import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { environment } from 'src/environments/environment';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { CookiesService } from 'src/app/services/cookie.service';
import { InitialState } from 'src/app/store/reducer';
import { ToggleOfferPrice } from 'src/app/pages/flight/store/actions';
import { Store } from '@ngrx/store';
import { NgRedux, select ,} from '@angular-redux/store';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	showStrip = false
	isModalOpen = false
	showStripMessage:boolean = true
	isAccDropDownShow: boolean = false;
	login: boolean = false;
	menuStatus: boolean = true;
	loggedInUserName: string = 'Hi, User';
	showOfferLi: boolean = false;
	userInfo: any = {};
	subscription: any;
	creditLimit: any;
	invalidCredit: any;
	cashLimit: any;
	cashSubscription: any;
	creditSubscription: any;
	isDiAccount: boolean;
	showEmulatedBy: boolean;
	warningMessage
	selectedErrorIndex: any;
	shortHandName: string;
	showInfoMessage = true;
	@Input() appVersion;
	heightAsPerMessage: string;
	@select() CommonReducers
	constructor(public dialog: MatDialog, 
		private router: Router,
		private bookingService: BookingRequestService,
		private commonService: CommonService, private affiliateService: AffiliateService, private cookiesService : CookiesService,
		private store: Store<InitialState>,
		private notificationService: NotificationService
		
		) { 
			this.CommonReducers.subscribe((items : any) => {
					console.log('GlobalMessage',items.globalMessage)
					if(items.globalMessage){
						this.warningMessage = items.globalMessage
					}
					this.handleMessageWrapperHeight()
				});
		}

	ngOnInit() {
		this.commonService.logOutStatus.subscribe(res => {
			this.userInfo = JSON.parse(localStorage.getItem('user_details'));
			if(this.userInfo != undefined) {
				if(this.userInfo['is_di_account'] != null) {
					this.isDiAccount = (this.userInfo['is_di_account'] == 'true')
				}
				else{
					this.isDiAccount = false;
				}

				this.login = true;
				this.loggedInUserName =  this.userInfo.firstName + ' ' + this.userInfo.lastName;
				this.getInitialName(this.userInfo.firstName + ' ' + this.userInfo.lastName)
				if (this.userInfo['emulated_by'] != undefined) {
					this.showEmulatedBy = true
				}
				else{
					this.showEmulatedBy = false
				}
				this.getBalance(this.userInfo.affiliateId)
				// this.cashSubscription = this.bookingService.cashLimit.subscribe(res=>{
				// 	this.cashLimit = res;
				// })
				this.creditSubscription = this.bookingService.creditLimit.subscribe(res=>{
					this.creditLimit = res;
				})
			}
			else{
				this.login = false;
			}
		})

		this.notificationService.messageStatus.subscribe(res=>{
			this.warningMessage = res;
			this.handleMessageWrapperHeight()
			
		})

		// if(this.cookiesService.getCookie("isBeta") == "false"){
		// 	this.switchToNormal()
		// }
	}
	handleMessageWrapperHeight(){
		setTimeout(() => {
			let height = document.querySelector('.message-wrapper') ? document.querySelector('.message-wrapper')['offsetHeight'] : 0;
			this.heightAsPerMessage = `${height}px`
			console.log('Message List height : ',this.heightAsPerMessage)
		}, 100);
	}

	async getBalance(id){
	await 	this.bookingService.getCreditLimit(true, 0,id,'');
	await 	this.bookingService.getCreditLimit(false, 0,id,'');
	}

	viewOffer() {
		this.store.dispatch(new ToggleOfferPrice(true));
		this.showOfferLi = true;
		this.isAccDropDownShow = false;
	}

	hideOffer() {
		this.store.dispatch(new ToggleOfferPrice(false));
		this.showOfferLi = false;
		this.isAccDropDownShow = false;
	}
	hamClick(){
		this.menuStatus = !this.menuStatus;       
		let menuStatus = {
			status : this.menuStatus
		}
		this.commonService.showMenu(this.menuStatus);
		localStorage.setItem('menuStatus',JSON.stringify(menuStatus))
	}

	forgetPassword() {
		const dialogRef = this.dialog.open(ForgetPasswordComponent, {
			width: 'auto',
			data: {}
		});
	}
	logout() {
		localStorage.removeItem('access_token');
		localStorage.clear();
		this.login = false;
		if(this.appVersion){
			this.cookiesService.setCookie('app-version',this.appVersion,30)
		}
		this.router.navigate(['login']);
		this.isAccDropDownShow = false;
	}

	resetSearchForm() {
		this.commonService.clearSearchFormFields(true);
	}

	showFooterDesign(value){
		this.commonService.showFooterDesign(value)
	}

	redirectTo(value) {
		if(value == 'hotel') {
			window.open(environment.hotelUrl, "_blank");

		}else if(value == 'insurance') {
			window.open(environment.insuranceUrl, "_blank");

		}
		else if(value == 'parks'){
			window.open(environment.parksUrl, "_blank");

		}
		else if(value == 'car'){
			window.open(environment.carUrl, "_blank");

		}
	}

	hideError(i){
		this.warningMessage.messageList.splice(i, 1);
		this.notificationService.showMessage(this.warningMessage)
	}
	showError(type){
		this.warningMessage['status'] = true
		let obj ={
			warningType : type,
			message : `${type} message.`
		}
		this.warningMessage['messageList'].push(obj)
		this.warningMessage['warningType'] = type
		this.notificationService.showMessage(this.warningMessage)
	}
	viewDetails(i){
		this.selectedErrorIndex = i
		this.handleMessageWrapperHeight()
	}
	showHideInfoMessage(){
		this.showInfoMessage = !this.showInfoMessage
	}

	getInitialName(fullName) {
		if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			let shorthandName = ""
			if (nameArr.length == 1) {
				shorthandName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1){
				let arr =[]
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							arr.push(element.substring(0, 1))
							// shorthandName += element.substring(0, 1)
						}
					}
				}
				shorthandName = arr.join(".")
			}
				
			this.shortHandName = shorthandName
			console.log('Shorthand name ', this.shortHandName)
		}

	}



	switchToNormal(){	  
		this.cookiesService.setCookie("isBeta","false",null)
		// window.location.href = environment.v3Url
	  }

	onCrossClick(){
		this.showStripMessage = !this.showStripMessage
	}
}
