import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookiesService } from './cookie.service';

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService {

	constructor( 
		private router: Router, private cookiesService: CookiesService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		var access_token = "";
		if (localStorage.getItem('access_token') == null) {
			if(window.location.hash.includes('template/print-ticket;searchId') || window.location.hash.includes('signup')) {

			}
			else{
				this.router.navigate(['login']);
			}
		}
		else {
			access_token = localStorage.getItem('access_token');
		}
		let deviceId = this.cookiesService.getCookie('deviceId');
		let confidenceScore = this.cookiesService.getCookie('confidenceScore');
		let fingerprintDataString = this.cookiesService.getCookie('fingerprintData');
		let fingerPrintRequestId = '';
		if(fingerprintDataString){
			try {
				fingerPrintRequestId = JSON.parse(fingerprintDataString) ? JSON.parse(fingerprintDataString).requestId : ''
			} catch (error) {
				
			}
		}
		var authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json').append('Authorization', access_token) });
		let searchId =  localStorage.getItem('flightSearchId') ?  localStorage.getItem('flightSearchId') : "";
		let bookingRef = sessionStorage.getItem('X-CURRENT-BOOKING-REFERENCE') ?  sessionStorage.getItem('X-CURRENT-BOOKING-REFERENCE') : "";
		let pnr = sessionStorage.getItem('X-CURRENT-PNR') ?  sessionStorage.getItem('X-CURRENT-PNR') : "";
		let invoiceNumber = sessionStorage.getItem('X-CURRENT-INVOICE') ?  sessionStorage.getItem('X-CURRENT-INVOICE') : "";
		if(localStorage.user_details != undefined) {
			let user = JSON.parse(localStorage.user_details);
			authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')
			.append('X-AFFILIATE-ID', user.primaryEmail)
			.append('X-AFFILIATE-NAME', user.companyName)
			.append('X-XL-CODE', user.xlAccountCode ? user.xlAccountCode : "" )
			.append('X-CLIENT-KEY', user.accountKey ? user.accountKey : "")
			.append('X-SEARCH-ID', searchId)
			.append('X-DEVICE-ID', deviceId)
			.append('X-CONFIDENCE-SCORE', confidenceScore)
			.append('X-FP-REQUEST-ID', fingerPrintRequestId)
			.append('X-BOOKING-REFERENCE', bookingRef)
			.append('X-PNR', pnr)
			.append('X-INVOICE-NO', invoiceNumber)
			.append('Authorization', access_token) }); 
			if (user.emulated_by) {
			authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')
			.append('X-AFFILIATE-ID', user.primaryEmail)
			.append('X-AFFILIATE-NAME', user.companyName)
			.append('X-XL-CODE', user.xlAccountCode ? user.xlAccountCode : "" )
			.append('X-CLIENT-KEY', user.accountKey ? user.accountKey : "")
			.append('X-SEARCH-ID', searchId)
			.append('X-BOOKING-REFERENCE', bookingRef)
			.append('X-PNR', pnr)
			.append('X-INVOICE-NO', invoiceNumber)
			.append('X-EMULATED-CODE', user.emulated_by)
			.append('Authorization', access_token) }); 
			}
		}
		else{
			authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')					
			.append('Authorization', access_token).append('X-DEVICE-ID', deviceId).append('X-CONFIDENCE-SCORE', confidenceScore)});
		}
		
		
		return next.handle(authReq)
	}

	randomString(length, chars) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}
}
