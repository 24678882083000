import { Component, ElementRef, OnInit, ViewChild, NgZone } from "@angular/core";
import { environmentConstant } from "../Utility/environmentConstant";
import { MatDialog } from "@angular/material";
import { ForgetPasswordComponent } from "../Common/forget-password/forget-password.component";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { LoginService } from "../services/login.service";
import { PostService } from "../services/post.service";
import { UrlService } from "../services/url.service";
import { CommonService } from "../services/common.service";
import { environment } from "src/environments/environment";
import { WebsocketService } from "../services/websocket.service";
// import { RecaptchaErrorParameters } from "ng-recaptcha";
import * as OwlCarousel from "ngx-owl-carousel-o";
import { resetApplicationState } from "@angular/core/src/render3/instructions";
import { ResourceLoader } from "@angular/compiler";
import { error } from "console";
import { AffiliateService } from "../services/affiliate.service";
import { FingerprintService } from "../services/fingerprint.service";
import { CookiesService } from "../services/cookie.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  homeSlider: any = environmentConstant.homePageSlider;
  flightSlider: any = environmentConstant.flightSlider;
  loginForm: FormGroup;
  hide;
  isLogin: boolean = false;
  isOtpModal: boolean = false;
  isOTResend: boolean = false;
  isOTPVerfing: boolean = false;
  emailNotFound: boolean = false;
  pwdNotFound: boolean = false;
  loginErrorMessage = '';
  EmulationErrorMsg: any;
  EmulationError: boolean = false;
  redirecUrl: any;
  dataofRegisterForm: any;
  siteKey = environment.reCaptchaSiteKey
  @ViewChild("otpModal") otpModal: ElementRef;
  @ViewChild("closeOtpModal") closeOtpModal: ElementRef;
  @ViewChild("captchaElemLogin") captchaElemLogin;
  @ViewChild("loginBtn") loginBtn;
  

  userInfo: any;
  loginToken: any;
  otpForm: FormGroup;
  respMessage: any;
  respType: string;
  captchaResponse: string | null = null;
  executeCaptchaInterval;
  refreshCaptchaInterval;
  showCaptcha = true;
  emulateUrl = ''
  isEmulate = false
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private service: PostService,
    private url: UrlService,
    private loginService: LoginService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private affiliateService: AffiliateService,
    private fingerprintService: FingerprintService,
    private cookiesService: CookiesService,
    private zone: NgZone
  ) {
  }

  ngOnInit() {
    if (localStorage.isLoaded == undefined) {
      localStorage.isLoaded = true;
      window.location.reload();
    }

    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      logCaptcha: ['']
    });
    this.otpForm = this.fb.group({
      otp: ["", Validators.required],
    });

    this.commonService.showLogout(false);
    var url = window.location.href.slice(window.location.href.indexOf("?") + 1);
    this.redirecUrl = this.activatedRoute.snapshot.params["redirectTo"];
    let sectionId = this.activatedRoute.snapshot.params["sectionId"];
    if (sectionId) {
      if (document.querySelector(`#${sectionId}`)) {
        document.querySelector(`#${sectionId}`).scrollIntoView();
      }
    }
    this.emulateUrl = url
    this.doEmulateLogin(this.emulateUrl,false);
    this.executeCaptcha(1000, 'init','executeCaptchaInterval');
    // this.executeCaptcha(90000, 'init_refresh','refreshCaptchaInterval');
  }

  executeCaptcha(timer = 1000, source = 'undefined', intervalName = 'executeCaptchaInterval'): void {
    this.captchaResponse = null
    if(this[intervalName]) clearInterval(this[intervalName])
    this[intervalName] = setInterval(() => {
      try {
        this.captchaElemLogin.execute();
        console.log('TIMER FROM SOURCE', source,timer)
      } catch (error) {

      }
    }, timer,source);
  }

  handleSuccess(captchaResponse){
    this.captchaResponse = captchaResponse
    clearInterval(this.executeCaptchaInterval)
  }

  handleError(error: any): void {
    console.error('Captcha error:', error);
  }
  
  handleExpired(): void {
    console.warn('Captcha expired, please try again.');
  }

  get email() {
    return this.loginForm.controls.email;
  }
  get password() {
    return this.loginForm.controls.password;
  }
  forgetPassword() {
    const dialogRef = this.dialog.open(ForgetPasswordComponent, {
      width: "auto",
      data: {},
    });
  }
  async submit(values, isReSendOtp) {
    if(!this.captchaResponse){
      this.showCaptcha = false;
      setTimeout(() => {
        this.showCaptcha = true
        this.executeCaptcha(1000, 'submit_invalid_token','executeCaptchaInterval');
      }, 100);
      return false;
    }
    values['logCaptcha'] = this.captchaResponse;
		const fingerPrintData = await this.generateFingerPrint();

    this.isLogin = true;
    this.otpForm.reset();
    values["isReSendOtp"] = isReSendOtp;
    this.isOTResend = false;
    this.respType = "success";
    this.respMessage = "";
    this.loginErrorMessage = ''
    this.service.postMethod(`${environment.adminUrl}/login`, values).subscribe(
      (data) => {
        this.captchaResponse = null;
        this.executeCaptcha(1000, 'submit_valid_response','executeCaptchaInterval');
        // let jsonData = JSON.parse(data['_body']);
        //  ;
        let jsonData = data;
        this.userInfo = jsonData["userInfo"];
        this.loginToken = jsonData["token"];
        if (jsonData["event"] == "validate_otp" && this.otpModal) {
          if (!isReSendOtp) {
            this.otpModal.nativeElement.click();
          } else {
            this.respType = "success";
            this.respMessage = "OTP resent.";
          }
          return false;
        }
        localStorage.setItem("access_token", jsonData["token"]);
        localStorage.setItem('systacc_access_token', jsonData["systaccToken"]);
        if(jsonData.hasOwnProperty('importPnrAccess')) {
          localStorage.setItem('import_pnr_access', jsonData['importPnrAccess']);
        }
        localStorage.setItem(
          "user_details",
          JSON.stringify(jsonData["userInfo"])
        );
        console.log(jsonData);
        this.commonService.showLogout(true);
        this.commonService.setDiAccountStatus(
          jsonData["userInfo"]["is_di_account"]
        );
        // this.router.navigate(['pages/dashboard']);
        localStorage.setItem("showFlyer", "true");
        if (
          this.redirecUrl &&
          this.redirecUrl != null &&
          !this.redirecUrl.includes("traveller")
        ) {
          if (this.redirecUrl.includes("http")) {
            window.location.href = this.redirecUrl;
          } else {
            this.router.navigate([this.redirecUrl]);
          }
        } else {
          if (localStorage.getItem("reload") != undefined) {
            let route = localStorage.getItem("reload");
            localStorage.removeItem("reload");
            if (route.includes("/booking-summary-detail")) {
              let searchId = route.split(";searchId=")[1];
              this.router.navigate(["/booking-summary-detail", { searchId }]);
            }
            // this.router.navigate([route]);
          } else {
            this.router.navigate(["pages/dashboard"]);
            // window.location.href = 'pages/dashboard'
          }
        }
      },
      (error) => {
        this.isLogin = false;
        this.showCaptcha = false;
        var msg = error.error;
        if (msg.err == "email not found") {
          this.emailNotFound = true;
          return false
        }
        if (msg.err == "password is not correct") {
          this.pwdNotFound = true;
          return false
        }
        this.loginErrorMessage = msg.err;
        setTimeout(() => {
          this.showCaptcha = true
          this.executeCaptcha(1000, 'submit_error_response','executeCaptchaInterval');
        }, 100);
        console.log(error);
      }
    );
    console.log(values);
  }

  validateOtp(isEmulate) {
    let reqObj = {
      otp: this.otpForm.value.otp,
      token: this.loginToken,
      email: this.userInfo.primaryEmail,
    };
    if(isEmulate){
      reqObj['email'] = this.userInfo.emulate_user_info.t_user_email
    }
    this.isOTPVerfing = true;
    this.respMessage = "";
    this.service
      .postMethod(`${environment.adminUrl}/login/validate`, reqObj)
      .subscribe(
        (data) => {
          // let jsonData = JSON.parse(data['_body']);
          //  ;
          this.respType = "success";
          this.closeOtpModal.nativeElement.click();
          this.isOTPVerfing = false;
          let jsonData = data;
          localStorage.setItem("access_token", jsonData["token"]);
          localStorage.setItem('systacc_access_token', jsonData["systaccToken"]);
          if(jsonData.hasOwnProperty('importPnrAccess')) {
            localStorage.setItem('import_pnr_access', jsonData['importPnrAccess']);
          }
          localStorage.setItem(
            "user_details",
            JSON.stringify(jsonData["userInfo"])
          );
          console.log(jsonData);
          this.commonService.showLogout(true);
          this.commonService.setDiAccountStatus(
            jsonData["userInfo"]["is_di_account"]
          );
          // this.router.navigate(['pages/dashboard']);
          localStorage.setItem("showFlyer", "true");
          if (
            this.redirecUrl &&
            this.redirecUrl != null &&
            !this.redirecUrl.includes("traveller")
          ) {
            if (this.redirecUrl.includes("http")) {
              window.location.href = this.redirecUrl;
            } else {
              this.router.navigate([this.redirecUrl]);
            }
          } else {
            if (localStorage.getItem("reload") != undefined) {
              let route = localStorage.getItem("reload");
              localStorage.removeItem("reload");
              if (route.includes("/booking-summary-detail")) {
                let searchId = route.split(";searchId=")[1];
                this.router.navigate(["/booking-summary-detail", { searchId }]);
              }
              // this.router.navigate([route]);
            } else {
              this.router.navigate(["pages/dashboard"]);
              // window.location.href = 'pages/dashboard'
            }
          }
        },
        (error) => {
          this.isOTPVerfing = false;
          this.isLogin = false;
          var msg = error.error;
          this.respType = "error";
          this.respMessage = "";
          if (msg.err) {
            this.respMessage = msg.err;
          }
        }
      );
  }

  removeError() {
    this.emailNotFound = false;
    this.pwdNotFound = false;
  }

  doEmulateLogin(url, isReSendOtp) {
    if (url.includes("key=")) {
      this.commonService.toggleLoader(true);
      this.activatedRoute.queryParams.subscribe((params) => {
        let flag = false;
        if (
          params["employeeCode"] == undefined ||
          params["employeeCode"] == null ||
          params["employeeCode"] == ""
        ) {
          flag = true;
        }
        if (
          params["reason"] == undefined ||
          params["reason"] == null ||
          params["reason"] == ""
        ) {
          flag = true;
        }
        if (
          params["key"] == undefined ||
          params["key"] == null ||
          params["key"] == ""
        ) {
          flag = true;
        }
        const reqObj = JSON.parse(JSON.stringify(params))
        reqObj["isReSendOtp"] = isReSendOtp;

        if (!flag) {
          this.isEmulate = true;
          this.service
            .postMethod(`${environment.adminUrl}/emulateLogin`, reqObj)
            .subscribe(
              (data) => {
                let jsonData = data;
               
                this.userInfo = jsonData["userInfo"];
                this.loginToken = jsonData["token"];

                if (jsonData["event"] == "validate_otp" && this.otpModal) {
                  if (!isReSendOtp) {
                    this.otpModal.nativeElement.click();
                  } else {
                    this.respType = "success";
                    this.respMessage = "OTP resent.";
                  }
                  this.commonService.toggleLoader(false);
                  return false;
                }
                localStorage.setItem("access_token", jsonData["token"]);
                localStorage.setItem('systacc_access_token', jsonData["systaccToken"]);
                localStorage.setItem(
                  "user_details",
                  JSON.stringify(jsonData["userInfo"])
                );
                this.commonService.showLogout(true);
                this.commonService.setDiAccountStatus(
                  jsonData["userInfo"]["is_di_account"]
                );
                if (params.hasOwnProperty("reference_no")) {
                  let bookingId = params["reference_no"];
                  this.router.navigate(["/pages/re-attempt", { bookingId }]);
                } else {
                  this.commonService.toggleLoader(false);
                  this.router.navigate(["pages/dashboard"]);
                }
              },
              (error) => {
                this.commonService.toggleLoader(false);
                var msg = error.error;
                if (msg != undefined) {
                  if (msg.err == "email not found") {
                    this.emailNotFound = true;
                  }
                  if (msg.err == "password is not correct") {
                    this.pwdNotFound = true;
                  } else {
                    this.EmulationErrorMsg = msg.err;
                    this.EmulationError = true;
                  }
                }
                console.log(error);
              }
            );
        } else {
          this.commonService.toggleLoader(false);
        }
      });
    }
  }
  // Registeration form data
  registerFormData = new FormGroup({
    selTitle: new FormControl("", Validators.required),
    txtFirstName: new FormControl("", [
      Validators.required,
      Validators.pattern("^[A-Za-z]+([A-Za-z]+)*"),
    ]),
    txtLastName: new FormControl("", [
      Validators.required,
      Validators.pattern("^[A-Za-z]+([A-Za-z]+)*"),
    ]),
    txtMobile: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(16),
      Validators.pattern("^[0-9]*"),
    ]),
    txtPrimaryEmail: new FormControl("", [
      Validators.required
    ]),
    txtCompanyName: new FormControl("", [Validators.required]),
    recaptcha: new FormControl("", [Validators.required]),
  });

  onRegisterSubmit() {
    this.dataofRegisterForm = null;

    this.affiliateService
      .createAffiliate(this.registerFormData.value)
      .subscribe(
        (res) => {
          if (res) {
            this.dataofRegisterForm = res;
            this.registerFormData.reset();
            // alert(this.dataofRegisterForm.message) //this is registration message response
          }
        },
        (err) => {
          this.dataofRegisterForm = err.error;
        }
      );
  }

  get txtFirstName() {
    return this.registerFormData.controls.txtFirstName;
  }
  get txtLastName() {
    return this.registerFormData.controls.txtLastName;
  }
  get Regemail() {
    return this.registerFormData.controls.txtPrimaryEmail;
  }
  get txtMobile() {
    return this.registerFormData.controls.txtMobile;
  }

  // main banners slider
  mainBanners = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    autoHeight: false,
    // autoplayHoverPause: true,
    autoplay: true,
    navSpeed: 300,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      //   400: {
      // 	items: 2
      //   },
      //   740: {
      // 	items: 3
      //   },
      //   940: {
      // 	items: 4
      //   }
    },
  };
  mainBannersSlides = [
    {id: 1, img: "assets/v3images/login-banners/air-india-premium-banner.png"},
    {id: 2, img: "assets/v3images/login-banners/diu-banner.jpg"},
    {id: 3, img: "assets/v3images/login-banners/airarabia-ras.jpg"},
    {id: 4, img: "assets/v3images/login-banners/Kenya-Airways-Banner.jpg"},
    {id: 5, img: "assets/v3images/login-banners/air-asia-surat.jpg"},
    {id: 6, img: "assets/v3images/login-banners/airarabia-abudhabi.jpg"},
    {id: 7, img: "assets/v3images/login-banners/vistara-reward-banner.jpg"},
    {id: 8, img: "assets/v3images/login-banners/StarAirline.jpg"},
    {id: 9, img: "assets/v3images/login-banners/Indigo_to_amsterdam.png"},
    {id: 10, img: "assets/v3images/login-banners/Banner.png" },
		{id: 11, img: "assets/v3images/login-banners/kenya-airways-banner.jpg"},
		{id: 12, img: "assets/v3images/login-banners/virtual-acc-banner.svg"},
    {id: 13, img: "assets/v3images/login-banners/aadesh-indigo-flyer.jpg"},
    {id: 14, img: "assets/v3images/login-banners/whatsapp-banner-aadesh.jpg"},
    {id: 15, img: "assets/v3images/login-banners/anniversary-banner.jpg"},
    {id: 16, img: "assets/v3images/login-banners/starair-jamnagar.jpg"},
    // {id: 16, img: "assets/v3images/login-banners/star-airline-india.jpg"},
    // {id: 17, img: "assets/v3images/login-banners/Qatar-Valentine-Banner.jpg"},
    // {id: 18, img: "assets/v3images/login-banners/Vistara-flight-Banner.jpg"},
    // {id: 19, img: "assets/v3images/login-banners/akasa-doha-banner.jpg"},
    // {id: 20, img: "assets/v3images/login-banners/starair-jamnagar.jpg"},
   
	 ];

  // testimonial slider
  testimonialSlider = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // nav: false,
    // autoplayHoverPause: true,
    autoplay: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      1400: {
        items: 3,
      },
    },
  };
  testimonialSlides = [
    {
      id: 1,
      pic: "../../../assets/clients/Shivani-Karia.png",
      name: "Shivani Karia",
      designation: "Vacation Vibes",
      comment:
        "If you’re looking for a travel agency that truly prioritises its customers, Aadesh Travels Pvt Ltd is the perfect choice. Their 24/7 customer service is reliable, efficient, and customer-focused, making them one of the best in the industry. I highly recommend their services in B2B market.",
    },
    {
      id: 2,
      pic: "../../../assets/clients/Rahimbhai-dhanani.png",
      name: "Rahimbhai Dhanani",
      designation: "Enjoy travels",
      comment:
        "Booking/Reissue flight tickets can sometimes be a hassle, but with Aadesh Travels Pvt Ltd, the entire process was smooth, quick, and effortless. From searching for the best fares to finalising booking, everything was seamless and stress-free.",
    },
    {
      id: 3,
      pic: "../../../assets/clients/Vijaybhai-Pindoriya.png",
      name: "Vijaybhai Pindoriya",
      designation: "Nar Naranyan Travels",
      comment:
        "Aadesh Travels consistently offers some of the best airfare deals in the market. Their strong partnerships with major airlines like Air India, Vistara, Emirates, Qatar Airways, and more ensure that customers get exclusive discounts and lower-than-market rates—especially for B2B bookings.",
    },
    {
      id: 4,
      pic: "../../../assets/clients/Shailesh-Kotecha.png",
      name: "Shailesh Kotecha",
      designation: "Krishna Tours and Travels",
      comment:
        "One of the standout features of Aadesh Travels is their excellent commission structure. They offer some of the best agent returns in the industry, ensuring that travel partners earn maximum profits on every booking.",
    },
  ];

  resendOTP() {
    this.isOTResend = true;
  }
  verifyOTP() {
    this.isOTPVerfing = true;
  }

  async generateFingerPrint() {
		try {
			const fingerPrintData = await this.fingerprintService.getFingerprint();
			this.cookiesService.setCookie('fingerprintData', JSON.stringify(fingerPrintData), 7);

			if(fingerPrintData) {
				const generatedDeviceId = fingerPrintData['visitorId'];
				this.cookiesService.setCookie('deviceId',generatedDeviceId, null)
		
				const confidence = fingerPrintData['confidence'];
				if(confidence) {
					this.cookiesService.setCookie('confidenceScore',confidence.score, null)
				}
			}
			
		} catch (error) {
			console.error('Error generating fingerprint:', error);
			throw error;
		}
	}
}
