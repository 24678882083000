import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AffiliateService } from "src/app/services/affiliate.service";
import { BookingService } from "src/app/services/booking/booking.service";
import { BookingService as BookingRequest } from "./booking.service";
import { MatTabChangeEvent } from "@angular/material";
import { PostService } from "src/app/services/post.service";
import { environment } from "../../../environments/environment";
import { BookingRequestService } from "src/app/services/booking-request.service";
import { TravellerService } from "src/app/services/traveller/traveller.service";
import { ExcelService } from "src/app/services/excel.service";
import { CalculatePriceService } from "src/app/services/calculate-price.service";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { Subscription } from "rxjs";

import * as moment from "moment-timezone";
import { join } from "path";
import { DateGmtFormatPipe } from "src/app/shared/pipes/date-gmt-format.pipe";
import { CentrifugeService } from "src/app/services/centrifuge.service";
import { InitialState } from "src/app/store/reducer";
import { NgRedux } from "@angular-redux/store";
import { InitializeSelectedSSR, ResetAddonSelection, SetSsr, ToggleAddonSummary } from "src/app/store/addons/actions";
import { Store } from '@ngrx/store';
import { NotificationService } from "src/app/services/notification.service";
// import undefined = require('firebase/empty-import');
@Component({
  selector: "app-traveller",
  templateUrl: "./traveller.component.html",
  styleUrls: ["./traveller.component.scss"],
})
export class TravellerComponent implements OnInit {
  passengerArr = [];
  activeAddonTab = 'seat-map';
  toggleAddon:boolean = false
  @ViewChild("closeactiveBtn") closeactiveBtn: ElementRef;
  @ViewChild("showPaymentInfo") showPaymentInfo: ElementRef;
  @ViewChild("showHoldInfo") showHoldInfo: ElementRef;
  @ViewChild("holdCheckBox") holdCheckBox: ElementRef;
  @ViewChild("showDuplicateBooking") showDuplicateBooking: ElementRef;
  @ViewChild("showPriceChange") showPriceChange: ElementRef;
  @ViewChild("showGroupfareModal") showGroupfareModal: ElementRef;

  maxDobDate = {
    "Adult" : new Date(moment().subtract(12, 'years')),
    "Child" : new Date(moment().subtract(2, 'years')),
    "Infant" : new Date()
  }
  // moment().subtract(12, 'years')
  minDobDate = {
    "Adult" : new Date(moment().subtract(100, 'years')),
    "Child" : new Date(moment().subtract(12, 'years')),
    "Infant" : new Date(moment().subtract(2, 'years'))
  }
  active = false;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  travellerForm: FormGroup;
  myDepControl = new FormControl();
  myGstControl = new FormControl();
  panelOpenState = false;
  departure: any;
  arrival: any;
  date;
  total;
  isOffer = false;
  netPayable: any;
  selectedFare;
  baseFare: any;
  netCommission: any;
  totalFare: any;
  adultBaseFare: any;
  adultTotalFare: any;
  adultTaxFare: number = 0;
  childBaseFare: number = 0;
  childTotalFare: number = 0;
  childTaxFare: number = 0;
  infBaseFare: number = 0;
  infTotalFare: number = 0;
  infTaxFare: number = 0;
  legs = [];
  airlineCode: any;
  totaltax: any;
  arrivalDate: string;
  departureDate: string;
  departureTime: string;
  arrivalTime: string;
  isDomestic: boolean = true;
  passengerList: FormArray;
  nationalityArr: any = [];
  noOfAdult: any;
  noOfChild: any;
  noOfInfant: any;
  tds: any;
  creditDetail: any = [];
  creditRemaining: string;
  creditLimit: string;
  invalidCredit: boolean = false;
  passengerDetails: any;
  isSpiceJet: boolean = false;
  totalPassengerCount: number = 0;
  totalPaxArr: any = [];
  openTravellerTab: boolean = false;
  step: number = 0;
  baggageArr: any = [];
  showBaggage: boolean = false;
  selectedBaggage: any = [];
  sectorArr: any = [];
  adminMarkup: number = 0;
  affMarkUp: number = 0;
  dummyAdultNameArr: any = [];
  gstArr: any = [];
  allgstArr: any = [];
  selectedGST: any;
  gstId: string = "";
  coversMin: any
  coversMax: any
  seatMapArr: any = [];
  ssrAvailability: any = [];
  showload = false;
  showloadError = false;
  showloadErrorMessage : any;
  showseatloadError: boolean;
  showseatloadErrorMessage: any;
  flightRequest: any;
  data: any = [];
  passengers = [1, 2, 3];
  request;
  isLCC: boolean = false;
  supplierName;
  subscription: any;
  selectedSeatArr: any;
  selectedBaggageArr: any;
  selectedAdditionalBaggageArr : any;
  selectedAncillaryArr: any;
  selectedMealArr: any;
  selectedWheelChairArr: any;

  seatCharges = 0;
  mealCharges = 0;
  baggageCharges = 0;
  additionalBaggageCharges = 0
  anicillaryCharges = 0;
  wheelChairCharges = 0;

  netTotalayable = 0;
  firbaseData = [];
  offerSubscription: Subscription;
  airlineArr: any;
  fareType = "";
  xmlSearchSsrLogsKey: ''
  xmlSearchSeatLogsKey: ''


  minDate: Date = new Date();

  paxSubmitted: boolean = false;
  contactSubmitted: boolean = false;

  current_time = new Date();
  date_diff;
  showHold: boolean = false;
  showAddons: boolean = true;
  supplierType: any;
  warningMessage: string;
  enableBooking: boolean;
  bookingErrorMsg: boolean;
  fareRule: any;
  affiliateMarkup: any;
  totalServiceCharge: any;
  totalGst: any;
  isDiAccount: boolean;
  limitType: any;
  isSelectedSMEMeals: boolean = true;

  isIncreaseInfantPrice: boolean = false;
  currentInfantPrice = 0;
  increasedInfantPrice = 0;
  hasFreeBaggage: boolean = false;
  hasFreeMeal: boolean = false;
  totalFreeMealPerLeg = 0;
  selectedPaymentMethod: any;
  affiliateDetails: any;
  isResponse: boolean;
  paymentStatusResponse: any;
  errorMessage: string;
  originalNetPayable: any;
  onlinePaymentCharge: number;
  onlinePaymentAmount: number;

  //Duplicate Booking Vars

  duplicateBookingMsg: any;
  disableBtn: boolean;

  isIncreasedPrice = false;
  oldTtotalNetPayable = 0;

  isHoldCurrentBooking = false;
  isDuplicate: boolean;

  messageList;
  isSeatAvailabilityCall: boolean = true;
  allowHold: boolean = false;
  bookingIssueInprogress: boolean = false;
  showSelectedSeatError: boolean = false;
  hideFareDetails: boolean;
  flight_ufid: any;
  channel_name: any;
  isGroupFare: boolean;
  ICentrifugeConfig: any;
  groupFareReceived: boolean;
	groupfareFoundStatus: string;
	groupFareValidTime: any;
  selectedSsrInRedux: any;
  subFareType: string;
  insurancePackageData:any;
  isInsuranceCoverExpanded = false;
  isInsuranceSelected = false;
  insuranceCoverViewCount = 2
  insuranceAmountPerPerson= 0;
  totalInsuranceAmount= 0;
  insuranceTravellerList = [];
  selectedInfantAssociate = new Map();
  warningMessageType: string;
  environment = environment
  sectionIndex={
    FLIGHT_DETAIL : 0,
    TRAVELLER_INFO : 1,
    PERSONAL_INFO : 2,
    GST_DETAILS : 3,
    ADD_ONS : 4,
    TRAVEL_INSURANCE : 5,
    PAYMENT : 6
  }
  isStudentFare=false;
  isSeniorCitizenFare=false;
  ssrAvailabilityStatus: { hasMeal: boolean; hasSeat: boolean; hasBaggage: boolean; hasAncillary: boolean; };
  isGfsSupplier = false
  ssrWarningMessage = false;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private fb: FormBuilder,
    private affiliateService: AffiliateService,
    private bookingService: BookingService,
    private postService: PostService,
    private bookingRequest: BookingRequest,
    private bookingRequestService: BookingRequestService,
    private travellerService: TravellerService,
    private excelServc: ExcelService,
    private db: AngularFireDatabase,
    private calculatePriceService: CalculatePriceService,
    private centrifugeService: CentrifugeService,
    private activatedRoute: ActivatedRoute,
    private ngRedux: NgRedux<InitialState>,
    private store: Store<InitialState>,
		private notificationService : NotificationService
  ) {
    this.showAddons = true;
    this.ngRedux.dispatch(ResetAddonSelection({}))
    this.ngRedux
		.select('SSrReducers')
		.subscribe((items : any) => {
			this.selectedSsrInRedux = items.selectedSsr;
		});

    this.ngRedux
		.select('CommonReducers')
		.subscribe((items : any) => {
      if(items.globalMessage){
        this.messageList = items.globalMessage
      }
		});

    this.ngRedux
		.select('InsuranceTravellerReducers')
		.subscribe((items : any) => {
      if(items.insuranceTravellers)this.insuranceTravellerList = items.insuranceTravellers.value;
			
      console.log("insuranceTravellerList from redux",this.insuranceTravellerList)
		});
    this.store.select('flightReducers').subscribe((items : any) => {
      this.isOffer = items.showOfferPrice ? items.showOfferPrice : false
    });
  }


 

  async ngOnInit() {
    
    // this.messageList = this.notificationService.messageObj;
    if (this.centrifugeService.handler && this.centrifugeService.connected) {
      this.centrifugeService.handler.disconnect();
    }
  
    this.hideFareDetails = false;
    this.flight_ufid = this.activatedRoute.snapshot.params["ufid"];
    if (this.flight_ufid) {
      this.isGroupFare = true;
      this.hideFareDetails = true;
    }
    this.channel_name = this.activatedRoute.snapshot.params["channel_name"];
    if (this.channel_name) {
      this.connectToCentri(this.channel_name);
    }
    this.travellerForm = this.fb.group({
      corporateGstNumber: [
        "",
        Validators.pattern(
          "^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$"
        ),
      ],
      corporateName: [""],
      corporateMobile: [
        "",
        [
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      corporateEmail: [""],
      additionalComments: [""],
      passengerList: this.fb.array([]),
      email: [Validators.required],
      phone: [
        "",
        [
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      mobile: [
        Validators.required,
        [
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      paxDetail: this.fb.array([]),
      "isMaskFareEnabled":[false]
    });


    // this.openDuplicateBookingModal();

    if (localStorage.user_details != undefined) {
      if (JSON.parse(localStorage.user_details)["is_di_account"] != null) {
        this.isDiAccount =
          JSON.parse(localStorage.user_details)["is_di_account"] == "true";
      } else {
        this.isDiAccount = false;
      }
    }
    this.subscription = this.bookingRequestService.getLimit.subscribe((res) => {
      this.creditLimit = res["creditLimit"];
      this.invalidCredit = res["invalidCredit"];
      this.limitType = res["type"];
    });
    this.setPaymentMethod("agent-balance", false,false);
    this.getNationality();
    this.commonService.getAirline(`airline`).subscribe((res) => {
      this.airlineArr = JSON.parse(res["_body"]);
      // console.log('Airline MAster', this.airlineArr)
    });
    this.flightRequest = JSON.parse(
      sessionStorage.getItem("ActiveSearchRequest")
    );
	if(this.flight_ufid){
		let flightRes = await this.getFlightFromStateManage(this.flight_ufid);
		if(flightRes){
			flightRes['priceType'] = flightRes['fares'][0].priceType;
			this.selectedFare = []
			this.selectedFare.push(flightRes['fares'][0])
			this.data.push(flightRes);
		}
		try {
			let groupRequestResp = await this.getGroupFareReqRes(this.flight_ufid)
			 
			if(groupRequestResp && groupRequestResp[0]){
				if(groupRequestResp[0].request_json && groupRequestResp[0].request_json.searchRequest){
					this.flightRequest = groupRequestResp[0].request_json.searchRequest
					this.noOfAdult = this.flightRequest.noOfAdults;
					this.noOfChild = this.flightRequest.noOfChild;
					this.noOfInfant = this.flightRequest.noOfInfants;
					sessionStorage.setItem("ActiveSearchRequest",JSON.stringify(groupRequestResp[0].request_json.searchRequest))
				}
				if(groupRequestResp[0].response_json && groupRequestResp[0].response_json){
					if(groupRequestResp[0].status == 'found'){
						let data = {
								data :groupRequestResp[0].response_json,
								status : "found"
							}
						this.handleNewGroupFare(data)
					}
				}
			}
		} catch (error) {
			
		}

	}else{
		this.data.push(JSON.parse(localStorage.getItem("selectedFlight")));
	}
    this.loadFareData();
    this.subFareType = ''
		if(
			this.data[0] && 
			this.data[0].fares[0] && 
			this.data[0].fares[0].supplierInfo && 
			this.data[0].fares[0].supplierInfo.subPriceType){
			this.subFareType = this.data[0].fares[0].supplierInfo.subPriceType
		}
    this.legs = this.data[0].segments[0].legs;
    this.airlineCode = this.data[0].airline.code;
    this.enableBooking = true;
    if (this.airlineCode == "ET") {
      this.enableBooking = false;
      this.bookingErrorMsg = true;
    }
    this.isLCC = await this.travellerService.checkLCC(this.airlineCode);
    if (this.isLCC) {
      let sKeys = [];
      let outBoundskey = {
        fareSellKey: this.selectedFare[0].supplierInfo
          ? this.selectedFare[0].supplierInfo.FareSellKey
          : "",
        journeyKey: this.selectedFare[0].supplierInfo
          ? this.selectedFare[0].supplierInfo.JourneySellKey
          : "",
      };
      sKeys.push(outBoundskey);
      this.data[0].supplierInfo.sKeys = sKeys;
    }
    if (this.isLCC) {
      this.getBaggage(this.data);
    }

    // passport expiry min  date
    this.minDate = new Date(this.legs[0].departureDateTime);
    let expiry_month = this.minDate.getMonth() + 6;
    this.minDate = new Date(this.minDate.setMonth(expiry_month));
    this.isDomestic = this.flightRequest.domestic;
    this.departure = this.flightRequest.destinationDetails.code;
    this.arrival = this.flightRequest.originDetails.code;
    let d = new Date(this.flightRequest.journeyStartDate);
    this.date = d.toDateString();
    this.noOfAdult = this.flightRequest.noOfAdults;
    this.noOfChild = this.flightRequest.noOfChild;
    this.noOfInfant = this.flightRequest.noOfInfants;
    this.totalPassengerCount =
      Number(this.noOfAdult) + Number(this.noOfChild) + Number(this.noOfInfant);
    for (let i = 1; i <= this.flightRequest.noOfAdults; i++) {
      this.passengerList = this.travellerForm.get("passengerList") as FormArray;
      this.passengerList.push(this.buildItem("Adult", i));
      this.setValidator(this.passengerList.length - 1);
    }
    for (let i = 1; i <= this.flightRequest.noOfChild; i++) {
      this.passengerList = this.travellerForm.get("passengerList") as FormArray;
      this.passengerList.push(this.buildItem("Child", i));
      this.setValidator(this.passengerList.length - 1);
    }
    for (let i = 1; i <= this.flightRequest.noOfInfants; i++) {
      this.passengerList = this.travellerForm.get("passengerList") as FormArray;
      this.passengerList.push(this.buildItem("Infant", i));
      this.setValidator(this.passengerList.length - 1);
    }

    this.bookingRequestService.getCreditLimit(
      this.isLCC,
      this.netPayable,
      this.flightRequest.affiliate,
      ""
    );
    
    if (this.data[0].supplierCode == "6E") {
      this.supplierName = "INDIGO";
    } else if (this.data[0].supplierCode == "SG") {
      this.supplierName = "SPICEJET";
    } else if (this.data[0].supplierCode == "G8") {
      this.supplierName = "GO_AIR";
    } else {
      this.supplierName = this.data[0].supplierCode;
    }

    if((this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')) == false){
      for(let i=0;i<this.DynamicFormControls.controls.length;i++){
         this.DynamicFormControls.controls[i]['controls']['title'].setValidators([Validators.required])
      }
    }
    
    if(
      this.data[0].fares[0].supplierInfo && 
      this.data[0].fares[0].supplierInfo.SUPPLIER && 
      this.data[0].fares[0].supplierInfo.SUPPLIER == 'gfs'
    ) {
      this.isGfsSupplier = true;
    }
    
    // this.offerSubscription = this.commonService.viewOfferStatus.subscribe(
    //   (data) => {
    //     this.isOffer = data;
    //   }
    // );
    this.showHold = true;
    this.showAddons = true;
    // date diff in minutes
    this.checkDateDiff()
    if (
      this.data[0].supplierCode == "airasia" ||
      this.data[0].supplierCode == "airasia_v2" || 
      this.data[0].airline.code == "QP" ||
      this.isGfsSupplier === true
    ) {
      this.showHold = false;
    }

    // Get Fare Rule
    let fareRuleObj = {
      supplierName: this.data[0].fares[0].supplierInfo
        ? this.data[0].fares[0].supplierInfo.SUPPLIER
        : "",
      classOfService: this.data[0].fares[0].supplierInfo
        ? this.data[0].fares[0].supplierInfo.ClassOfService
        : "",
      fareBasisCode: this.data[0].fares[0].supplierInfo
        ? this.data[0].fares[0].supplierInfo.FareBasisCode
        : "",
      ruleNumber: this.data[0].fares[0].supplierInfo
        ? this.data[0].fares[0].supplierInfo.RuleNumber
        : "",
      signature: this.data[0].fares[0].supplierInfo
        ? this.data[0].supplierInfo.Signature
        : "",
      supplierInfo: this.data[0].fares[0].supplierInfo,
    };

    if (this.data[0].supplierCode == "SABRE") {
      fareRuleObj.supplierName = this.data[0].supplierCode;
      let supplierInfo = {
        Origin: this.data[0].segments[0].originAirport.code,
        Destination: this.data[0].segments[0].destinationAirport.code,
        TravelDate:
          this.data[0].segments[0].legs[0].supplierInfo.DepartureDateTime,
      };
      fareRuleObj["supplierInfo"] = supplierInfo;
      fareRuleObj["fareBasisCode"] = this.data[0].fares[0].supplierInfo
        ? this.data[0].fares[0].supplierInfo.FareBasisCode
        : "";
    } else if (this.data[0].supplierCode == "AMADEUS_V4" || this.data[0].supplierCode == "AMADEUS_STUD" || this.data[0].supplierCode == "AMADEUS_STUD"  || this.data[0].supplierCode == "VERTEIL" || this.data[0].supplierCode == "SABRE") {
      let flightReq = JSON.parse(sessionStorage.ActiveSearchRequest);
      fareRuleObj["supplierName"] = this.data[0].supplierCode;
      let supplierInfo = {
        travellerInfo: {
          ADT: flightReq["noOfAdults"],
          CHD: flightReq["noOfChild"],
          INF: flightReq["noOfInfants"],
        },
        sectors: [
          {
            departureDateTime:
              this.data[0].segments[0].legs[0].departureDateTime,
            departureCityCode:
              this.data[0].segments[0].legs[0].originAirport["code"],
            arrivalCityCode:
              this.data[0].segments[0].legs[0].destinationAirport["code"],
            airlineCode: this.data[0].segments[0].legs[0].operatingAirlineCode,
            flightNum: this.data[0].segments[0].legs[0].flightNumber,
            bookingClass: this.data[0].segments[0].legs[0].cabinClass,
          },
        ],
      };
      fareRuleObj["supplierInfo"] = supplierInfo;
    }
    this.getFareRule(fareRuleObj);
    if (localStorage.user_details != undefined) {
      this.affiliateDetails = JSON.parse(localStorage.user_details);
    } else {
      this.affiliateDetails = null;
    }
    var url = window.location.href.slice(window.location.href.indexOf("?") + 1);
    this.checkPayment(url);
    this.getInsuranceData(this.flightRequest)
    // this.duplicateBookingMsg = {
    // 	text : "You've a duplicate booking with reference no. : AT5003160",
    // 	reference_no : "AT5003160",
    // }
    // this.openDuplicateBookingModal()

  }

  checkDateDiff(){
    this.date_diff = this.commonService.timeDuration2(
      new Date(this.current_time),
      new Date(this.legs[0].departureDateTime.split("+")[0])
    );
    if (this.date_diff < 4320 && this.data[0].airline.code == "6E") {
      this.showHold = false;
    }
    if (
      this.date_diff < 4320 &&
      (this.data[0].airline.code == "G8" || this.data[0].airline.code == "SG")
    ) {
      this.showHold = false;
    }
    
  }

  getFlightFromStateManage(key) {
	return new Promise((resolve, reject) => {
		this.postService.getMethod(`${environment.url}/state/${key}`).subscribe((res : any) => {
			if(JSON.parse(res["_body"])) {
				resolve(JSON.parse(res["_body"]).data)
			}
			
		}, err => {
			console.log("Error in booking : ", err)
			resolve(err['error']);
		})
	})
}
	getGroupFareReqRes(flight_ufid) {
	return new Promise((resolve, reject) => {
		this.postService.getMethod(`${environment.baseUrl}/scrapers/group-fare?ufid=${flight_ufid}`).subscribe((res : any) => {
		// this.postService.getMethod(`http://localhost:2221/group-fare?ufid=${flight_ufid}`).subscribe((res : any) => {
			if(JSON.parse(res["_body"])) {
				resolve(JSON.parse(res["_body"]).data)
			}
			
		}, err => {
			console.log("Error in booking : ", err)
			resolve(err['error']);
		})

	})
}

  connectToCentri(channelName) {
    try {
      this.ICentrifugeConfig = {
        url: environment.centri_ws,
        token: environment.centri_token,
        user: "Daud",
        timestamp: (Date.now() / 1000) | 0,
        debug: true,
        reconnect: true,
      };
      this.centrifugeService.connect(this.ICentrifugeConfig);
      this.centrifugeService.handler.subscribe(channelName, (ctx) => {
        this.handleNewGroupFare(ctx.data);
      });
      // this.centrifuge.connect();
    } catch (error) {
      console.log(error);
    }
  }
 async handleNewGroupFare(data: any) {
	  
	  if(data.status == 'found'){
		  let selectedFare = this.selectedFare[0]
		  let fareInfo = data.data.fare
		  let adultFare = fareInfo.travellerFare.Adult
		  let childFare;
		  if(fareInfo.travellerFare.Child){
			childFare = fareInfo.travellerFare.Child
		  }
		  if(adultFare && adultFare['Total fare']){
			selectedFare['travellerFares']['ADT'] = await this.getGroupTravellerFare(adultFare,'ADT')
		  }
		  if(childFare && childFare['Total fare']){
			selectedFare['travellerFares']['CHD'] = await this.getGroupTravellerFare(childFare,'CHD')
		  }
		  let totalFareComp = await this.getTotalFareComponent(adultFare,childFare)
		   
		  selectedFare['base'] = totalFareComp['base']
		  selectedFare['priceType'] = 'GROUP_FARE'
		  selectedFare['total'] = totalFareComp['total']
		  selectedFare['fareComponents'] = totalFareComp['fareComponents']
		  try {
			this.groupFareValidTime = data.data.validTill.split("( Valid till : ")[1].split(")")[0].trim()
		  } catch (error) {
			  
		  }
		  let groupFareInfo = data.data
		  groupFareInfo['validTill'] = this.groupFareValidTime
		  selectedFare['groupFareInfo'] =groupFareInfo
		  selectedFare['supplierInfo']['groupFareInfo'] =groupFareInfo
		  selectedFare['isCustomBookingFailed'] = true
		  this.data[0].selectedFare = selectedFare
		  this.groupFareReceived = true
		  this.groupfareFoundStatus = 'found';
		  
		  this.loadFareData()
		  this.hideFareDetails = false
	  }else if(data.status == 'not-found'){
		this.hideFareDetails = true
		this.groupfareFoundStatus = 'not-found';
	  }
    console.log(data);
  }

 async  getTotalFareComponent(adultFare,childFare){
	let base = 0
	let OTHER_TAX = 0
	let NET_PAYABLE = 0
	let ADMIN_SERVICE_CHARGE = 0
	let GST = 0
	if(adultFare && adultFare['Total fare']){
		let baseAmnt = Number(adultFare['Base fare'])
		console.log('base : ',baseAmnt)
		base += (Number(baseAmnt)*Number(this.noOfAdult))
		let OT = Number(adultFare['Tax'])
		console.log('OTHER_TAX : ',OT)
		OTHER_TAX += (Number(OT)*Number(this.noOfAdult))

		ADMIN_SERVICE_CHARGE += Number(adultFare['Service Charge'] ? adultFare['Service Charge'] : 0)*(Number(this.noOfAdult))
		GST += Number(adultFare['GST'] ? adultFare['GST'] : 0)*(Number(this.noOfAdult))
		
		console.log('Adult fare : ')
	}
	
	
	if(childFare && childFare['Total fare']){
		let baseAmnt = Number(childFare['Base fare'])
		console.log('base : ',baseAmnt)
		base += (Number(baseAmnt)*this.noOfChild)
		let OT = Number(childFare['Tax'])
		console.log('OTHER_TAX : ',OT)
		OTHER_TAX += (Number(OT)*this.noOfChild)

		ADMIN_SERVICE_CHARGE += Number(childFare['Service Charge'] ? childFare['Service Charge'] : 0)*(Number(this.noOfChild))
		GST += Number(childFare['GST'] ? childFare['GST'] : 0)*(Number(this.noOfChild))

		console.log('Child fare : ')
	}
	NET_PAYABLE += (base+OTHER_TAX+ADMIN_SERVICE_CHARGE+GST)
	console.log('NET_PAYABLE : ',NET_PAYABLE)

	let fareComponents ={
		"NET_PAYABLE": NET_PAYABLE,
		"TDS": 0,
		"AFFILIATE_MARKUP": 0,
		"GST": GST,
		"OTHER_TAX": OTHER_TAX,
		"ADMIN_SERVICE_CHARGE": ADMIN_SERVICE_CHARGE,
		"SGST07": 0,
		"COMMISSION": 0,
		"ADMIN_MARKUP": 0
	}
	let returnObj = {
		"base" : base,
		"total" : NET_PAYABLE,
		"fareComponents" : fareComponents
	}
	return returnObj
  }

  async getGroupTravellerFare(fare,type){
	
	let base = Number(fare['Base fare'])
	let OTHER_TAX = Number(fare['Tax'])
	let ADMIN_SERVICE_CHARGE = Number(fare['Service Charge'] ? fare['Service Charge'] : 0)
	let GST = Number(fare['GST'] ? fare['GST'] : 0)
	let NET_PAYABLE = Number(base)+Number(OTHER_TAX);
	let obj = {
        "travellerType": type,
        "base": base,
        "total": NET_PAYABLE,
        "fareComponents": {
          "COMMISSION": 0,
          "NET_PAYABLE": NET_PAYABLE,
          "TDS": 0,
          "AFFILIATE_MARKUP": 0,
          "OC": 0,
          "OT": OTHER_TAX,
          "YQ": 0,
          "K3": 0,
          "ADMIN_MARKUP": 0,
          "GST": GST,
          "ADMIN_SERVICE_CHARGE": ADMIN_SERVICE_CHARGE
        },
        "supplierInfo": {
          "IATA_COMMISSION_TYPE": "RB",
          "IATA_COMMISSION": "0.0",
          "PLB_COMMISSION_TYPE": "RY",
          "CASHBACK_COMMISSION_TYPE": "VL",
          "IATA_COMMISSION_VALUE": "0.0",
          "PLB_COMMISSION_VALUE": "0",
          "CASHBACK_COMMISSION": "0.0",
          "IGST": 91,
          "CASHBACK_COMMISSION_VALUE": "0.0",
          "PLB_COMMISSION": "0"
        }
      }
	  return obj
	}
	
  loadFareData() {
    this.total = this.data[0].selFlightPrice;
    const localThis = this;
    this.selectedFare = [];
    let loadRepricing = false;
    if (
      this.data[0].selectedFare != undefined &&
      this.data[0].selectedFare != null &&
      this.isIncreasedPrice
    ) {
      this.selectedFare.push(this.data[0].selectedFare);
      loadRepricing = true;
      this.data[0].selectedFare = null;
    }else if(
		this.data[0].selectedFare != undefined &&
		this.data[0].selectedFare != null &&
		this.groupFareReceived
	  ){
		this.selectedFare.push(this.data[0].selectedFare);
		loadRepricing = true;
		this.data[0].selectedFare = null;
	} else {
      this.selectedFare = this.data[0].fares.filter((option) => {
        return option.priceType == localThis.data[0].priceType;
      });
    }

    this.fareType = this.selectedFare[0].priceType;
    this.supplierType = this.selectedFare[0].supplierInfo
      ? this.selectedFare[0].supplierInfo["SUPPLIER"]
      : "";
    this.netCommission = this.selectedFare[0].fareComponents.COMMISSION;
    this.affiliateMarkup = this.selectedFare[0].fareComponents.AFFILIATE_MARKUP;
    this.totalServiceCharge =
      this.selectedFare[0].fareComponents.ADMIN_SERVICE_CHARGE;
    this.totalGst = this.selectedFare[0].fareComponents.GST;
    this.tds = this.selectedFare[0].fareComponents.TDS;
    this.data[0].fares = this.selectedFare;
    this.baseFare = this.selectedFare[0].base;
    this.totalFare = this.selectedFare[0].total;
    this.calculateNetPayable(loadRepricing);
    this.totaltax =
      this.totalFare - this.baseFare + this.adultTaxFare + this.childTaxFare;

    if (this.selectedFare[0].travellerFares.ADT) {
      this.adultBaseFare = this.selectedFare[0].travellerFares.ADT.base;
      this.adultTotalFare = this.selectedFare[0].travellerFares.ADT.total;
      this.adultTaxFare = this.calculatePriceService.calculateTax(
        this.selectedFare[0].travellerFares.ADT.fareComponents
      );
    }
    if (this.selectedFare[0].travellerFares.CHD) {
      this.childBaseFare = this.selectedFare[0].travellerFares.CHD.base;
      this.childTotalFare = this.selectedFare[0].travellerFares.CHD.total;
      this.childTaxFare = this.calculatePriceService.calculateTax(
        this.selectedFare[0].travellerFares.CHD.fareComponents
      );
    }
    if (this.selectedFare[0].travellerFares.INF) {
      this.infBaseFare = this.selectedFare[0].travellerFares.INF.base;
      this.infTotalFare = this.selectedFare[0].travellerFares.INF.total;
      this.infTaxFare = this.calculatePriceService.calculateTax(
        this.selectedFare[0].travellerFares.INF.fareComponents
      );
    }

    if (loadRepricing && !this.groupFareReceived) {
      this.openPriceChangeModal();
    }
  }

  viewBooking(reference) {
    window.open(
      `${environment.baseUrl}/#/booking-summary-detail;searchId=${reference}`,
      "_blank"
    );
  }

  checkPayment(url) {
    if (url.includes("paymentIndex=1")) {
      this.active = true;
      this.activatedRoute.queryParams.subscribe((params) => {
        let amount = params["amount"];
        let orderID = params["orderId"];
        let transId = params["referenceNumber"];
        let hasKey = params["key"];

        var formValue = JSON.parse(localStorage.getItem("formValue"));
        this.setFormValue(formValue);
        if (!location.href.includes("error=true") && formValue != null) {
          let obj = {
            orderId: orderID,
            trackingId: transId,
            amount: amount,
            key: hasKey,
            status: "success",
          };
          formValue["chequeNumber"] = orderID;
          formValue["transactionRef"] = transId;
          try {
            if (formValue.baggage) {
              this.getBaggageEvent(formValue.baggage);
            }
            if (formValue.seats) {
              this.getSeatEvent(formValue.seats);
            }
            if (formValue.meals) {
              this.getMealEvent(formValue.meals);
            }
            if (formValue.wheelChairs) {
              this.getWheelChairEvent(formValue.wheelChairs);
            }
            if (formValue.ancillaries) {
              this.getAncillaryEvent(formValue.ancillaries);
            }
          } catch (error) {
            console.log("Error while setting Add-ons : ", error);
          }
          if (!params.hasOwnProperty("ft-cash")) {
            this.commonService.hdfcPayment(obj, "getPaymentStatus").subscribe(
              (data) => {
                if (data["_body"] != "") {
                  let response = JSON.parse(data["_body"]);
                  if (response.length > 0) {
                    this.isResponse = true;
                    this.paymentStatusResponse = response[0].json_doc_response;
                    window.history.replaceState(
                      {},
                      document.title,
                      "/#/pages/" + "traveller"
                    );
                    this.doBooking(formValue);
                  } else {
                    this.router.navigate([
                      `error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`,
                    ]);
                    this.isResponse = false;
                    console.log("Failed Payment");
                  }
                } else {
                  this.router.navigate([
                    `error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`,
                  ]);
                  this.errorMessage =
                    "Sorry ! Unable to process now, please try again.";
                  console.log(this.errorMessage);
                }
              },
              (error) => {
                console.log(error);
              }
            );
          } else {
            console.log("Booking with ft-Cash");
            window.history.replaceState(
              {},
              document.title,
              "/#/pages/" + "traveller"
            );
            this.doBooking(formValue);
          }
        } else {
          this.router.navigate([
            `error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`,
          ]);
          this.errorMessage =
            "Sorry ! Unable to process now, please try again.";
          console.log(this.errorMessage);
        }
        console.log(params); // Print the parameter to the console.
      });
    }
  }
  setFormValue(formValue) {
    //Set Pax Info, Contact Info, Gst Info,
  }
  getFareRule(fareRuleObj) {
    this.postService
      .postMethod(`${environment.url}/getFareRule`, fareRuleObj)
      .subscribe(
        (data) => {
          try {
            if (data != "" && data != null) {
              this.fareRule = data["fareRule"];
            } else {
              this.fareRule = "No Fare Rule Found";
            }
            this.data[0]["fareRule"] = this.fareRule;
            console.log("fareRule ", data);
          } catch (Exception) {
            console.log(Exception);
          }
        },
        (error) => {
          this.fareRule = "No Fare Rule Found";
          this.data[0]["fareRule"] = this.fareRule;
        }
      );
  }

  getBaggage(selectedFlight) {
    console.log("Selected flight before appending baggage", selectedFlight);
    let flightRequest = JSON.parse(sessionStorage.ActiveSearchRequest);

    let supplierInfo = selectedFlight[0].fares[0].supplierInfo;
    if (supplierInfo == null) {
      return false;
    }
    let supplierName = supplierInfo.SUPPLIER;
    if (supplierName == undefined) {
      supplierName = selectedFlight[0].airline.code;
    }
    let reqObj = {
      supplierName: supplierName,
      classOfService: supplierInfo.ClassOfService,
      fareBasisCode: supplierInfo.FareBasisCode,
      ruleNumber: supplierInfo.RuleNumber,
      signature: selectedFlight[0].supplierInfo.Signature,
      supplierInfo: {
        FareSellKey: supplierInfo.FareSellKey,
        JourneySellKey: supplierInfo.JourneySellKey,
        travellers: {
          ADT: flightRequest.noOfAdults,
          CHD: flightRequest.noOfChild,
          INF: flightRequest.noOfInfants,
        },
        FareMap: supplierInfo,
        FlightNumber: selectedFlight[0].segments[0].legs[0].flightNumber,
        BoardPoint: selectedFlight[0].segments[0].legs[0].originAirport.code,
        OffPoint: selectedFlight[0].segments[0].legs[0].destinationAirport.code,
        DepartureDateTime:
          selectedFlight[0].segments[0].legs[0].departureDateTime,
        ArrivalDateTime: selectedFlight[0].segments[0].legs[0].arrivalDateTime,
      },
    };

    console.log("Baggage req :", reqObj);
    this.travellerService.getBaggage(reqObj).subscribe(
      (res) => {
        let freeCheckInBaggage = JSON.parse(res["_body"]);
        let segment = selectedFlight[0].segments;
        for (let i = 0; i < segment.length; i++) {
          const legs = segment[i].legs;
          for (let j = 0; j < legs.length; j++) {
            const elm = legs[j];
            if (!elm.hasOwnProperty("freeCheckInBaggage")) {
              elm["freeCheckInBaggage"] = freeCheckInBaggage;
            } else {
              if (
                elm.freeCheckInBaggage == null ||
                elm.freeCheckInBaggage == undefined
              ) {
                elm["freeCheckInBaggage"] = freeCheckInBaggage;
              }
            }
          }
        }

        selectedFlight[0].segments = segment;
        console.log("Selected flight after appending baggage", selectedFlight);
      },
      (err) => {
        console.log("Error", err);
      }
    );
  }

  setPersonalDetails() {
    let user_details = JSON.parse(localStorage.user_details);
    if (!this.travellerForm.controls.email.touched) {
      this.travellerForm.controls.email.setValue(user_details.primaryEmail);
    }
    if (this.travellerForm.controls.email.value == null) {
      this.travellerForm.controls.email.setValue(user_details.primaryEmail);
    }
    this.travellerService.viewAllGSTbyid().subscribe(
      (res) => {
        this.allgstArr = res;
        if (this.allgstArr.length > 0) {
          console.log("All Saved GST", this.allgstArr);
        }
      },
      (err) => {}
    );
  }
  selectGST(item) {
    this.selectedGST = {};
    this.selectedGST = item;
  }
  setGender(value, i) {
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.gender.setValue("Male");
    if (value == "Mr") {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.gender.setValue("Male");
    }
    if (value == "Mrs") {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.gender.setValue("Female");
    }
    if (value == "Ms") {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.gender.setValue("Female");
    }
  }
  public getSeatEvent(data) {
    this.seatCharges = 0;
    data = data.flat()
    data.forEach((v) => {
      if (v.seats != null && v.seats != undefined) {
        v.seats.forEach((el) => {
          if (el.price != undefined) {
            this.seatCharges += Number(el.price);
          }
        });
      }
    });
    this.selectedSeatArr = data;
    this.ngRedux.dispatch(SetSsr(data));
    this.calculateNetPayable(false);
  }




  public getBaggageEvent(data) {
    this.baggageCharges = 0;
    data = data.flat()
    data.forEach((v) => {
      if (v.baggage != null && v.baggage != undefined) {
        v.baggage.forEach((el) => {
          if (el.price != undefined) {
            this.baggageCharges += Number(el.price);
          }
        });
      }
    });
    this.selectedBaggageArr = data;
    this.ngRedux.dispatch(SetSsr(data));
    this.calculateNetPayable(false);
  }
  public getAdditionalBaggageEvent(data) {
    this.additionalBaggageCharges = 0;
    data = data.flat()
    data.forEach((v) => {
      if (v.additionalBaggage != null && v.additionalBaggage != undefined) {
        v.additionalBaggage.forEach((el) => {
          if (el.price != undefined) {
            this.additionalBaggageCharges += Number(el.price);
          }
        });
      }
    });
    this.selectedAdditionalBaggageArr = data;
    this.ngRedux.dispatch(SetSsr(data));
    this.calculateNetPayable(false);
  }

  public getAncillaryEvent(data) {
    this.anicillaryCharges = 0;
    data = data.flat()
    data.forEach((v) => {
      if (v.ancillary != null && v.ancillary != undefined) {
        v.ancillary.forEach((el) => {
          if(el.selectedItems){
            el.selectedItems.forEach(element => {
              this.anicillaryCharges += Number(element.totalFare);
            });
          }
        });
      }
    });
    this.selectedAncillaryArr = data;
    this.ngRedux.dispatch(SetSsr(data));
    this.calculateNetPayable(false);
  }


  public getWheelChairEvent(data) {
    this.wheelChairCharges = 0;
    data = data.flat()
    data.forEach((v) => {
      if (v.wheelChairs != null && v.wheelChairs != undefined) {
        v.wheelChairs.forEach((el) => {
          if (el.price != undefined) {
            this.wheelChairCharges += Number(el.price);
          }
        });
      }
    });
    this.selectedWheelChairArr = data;
    this.ngRedux.dispatch(SetSsr(data));
    this.calculateNetPayable(false);
  }

  public getMealEvent(data) {
    this.mealCharges = 0;
    data = data.flat()
    data.forEach((v) => {
      if (v.meals != null && v.meals != undefined) {
        v.meals.forEach((el) => {
          if (el.price != undefined) {
            this.mealCharges += Number(el.price);
          }
        });
      }
    });

    this.selectedMealArr = data;
    this.ngRedux.dispatch(SetSsr(data));
    this.calculateNetPayable(false);
  }
  calculateNetPayable(loadRepricing) {
    this.netPayable = this.totalFare;
    if (this.selectedFare[0].fareComponents.hasOwnProperty("NET_PAYABLE")) {
      this.netPayable = Math.round(
        this.selectedFare[0].fareComponents.NET_PAYABLE
      );
      this.netPayable = Math.round(
        this.netPayable +
          this.mealCharges +
          this.seatCharges +
          this.baggageCharges+
          this.anicillaryCharges+
          this.additionalBaggageCharges+
          this.wheelChairCharges
      );
      // this.selectedFare[0].fareComponents.NET_PAYABLE = this.netPayable;
    } else {
      this.netPayable = Math.round(
        this.netPayable +
          this.mealCharges +
          this.seatCharges +
          this.baggageCharges+
          this.anicillaryCharges+
          this.additionalBaggageCharges+
          this.wheelChairCharges
      );
    }
    this.originalNetPayable = this.netPayable;
    this.netTotalayable = Math.round(
      this.totalFare + this.mealCharges + this.seatCharges + this.baggageCharges+ this.anicillaryCharges+ this.additionalBaggageCharges+ this.wheelChairCharges
    );
    if (this.selectedPaymentMethod != "agent-balance") {
      this.onlinePaymentAmount = Math.round(
        Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100
      );
      this.onlinePaymentCharge = Math.round(
        (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100
      );
      // this.netPayable = Math.round(Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
    }
    // this.netTotalayable = Math.round(Number(this.netTotalayable) + (Number(this.netTotalayable) * Number(environment.payment_gateway_charges)) / 100);
    this.data[0].fares[0].total = this.netTotalayable;
    this.bookingRequestService.getCreditLimit(
      this.isLCC,
      this.netTotalayable,
      this.flightRequest.affiliate,
      ""
    );
    if (!loadRepricing) {
      this.oldTtotalNetPayable = this.netTotalayable;
    }
  }
  refreshCreditLimit() {
    this.bookingRequestService.getCreditLimit(
      this.isLCC,
      this.netTotalayable,
      this.flightRequest.affiliate,
      ""
    );
  }
  ngOnDestroy() {
    try {
      this.subscription.unsubscribe();
    } catch (error) {
      console.log("Error occured while unsubscribing on ngDestroy : ", error);
    }
  }

  get DynamicFormControls() {
    return <FormArray>this.travellerForm.get("passengerList");
  }
  get corporateEmail() {
    return this.travellerForm.controls.corporateEmail;
  }
  get corporateMobile() {
    return this.travellerForm.controls.corporateMobile;
  }
  get corporateGstNumber() {
    return this.travellerForm.controls.corporateGstNumber;
  }
  // validation for traveller form
  get email() {
    return this.travellerForm.controls.email;
  }
  get mobile() {
    return this.travellerForm.controls.mobile;
  }

  getBaggageDetails(code) {
    this.affiliateService.getBaggageDetails(code).subscribe((res) => {
      this.baggageArr = res;
    });
  }

  baggageOnChange(el) {
    let filteredData = this.baggageArr.filter((v) => {
      if (el.value == v.id) {
        return v;
      }
    });
    if (filteredData.length > 0) {
      this.selectedBaggage = filteredData;
      this.showBaggage = true;
      this.totalFare =
        this.selectedFare[0].total +
        this.selectedBaggage[0].this.data[0].fares[0].totalPRICE;
      this.total = this.selectedFare[0].total + this.selectedBaggage[0].PRICE;
    } else {
      this.selectedBaggage = [];
      this.showBaggage = false;
      this.totalFare = this.selectedFare[0].total;
      this.total = this.selectedFare[0].total;
    }
    console.log(filteredData);
  }

  // function to build formarray object for passengers
  buildItem(passengerType, i) {
    return this.fb.group({
      title: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      gender: ["", Validators.required],
      dob: [""],
      passportNo: [""],
      nationality: [""],
      issuingCountry: [""],
      expiryDate: [""],
      associateInfo: [""],
      passengerType: [passengerType],
      specialServices: [""],
      frequentflyers: [""],
      frequentflyersNumber: [""],
      mealServices: [""],
      seatPrefences: [""],
      baggageServices: [""],
      travellerId: [""],
      studentId: [""],
      seniorCitizenId: [""],
      seniorCitizen: this.selectedFare && this.selectedFare[0] && this.selectedFare[0].supplierInfo && this.selectedFare[0].supplierInfo.subPriceType == 'SRCT' ? true : false,
      student : this.selectedFare && this.selectedFare[0] && this.selectedFare[0].supplierInfo && this.selectedFare[0].supplierInfo.subPriceType == 'STUD' ? true : false

    });
  }
  setValidator(i) {
    if (!this.isDomestic) {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.dob.setValidators([Validators.required]);

      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.passportNo.setValidators([Validators.required]);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.expiryDate.setValidators([Validators.required]);
    } else {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.dob.setValidators(null);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.passportNo.setValidators(null);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.expiryDate.setValidators(null);
    }

    let passType =
      this.travellerForm.controls.passengerList["controls"][i].controls
        .passengerType.value;
    if (passType != "Adult") {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.associateInfo.setValidators([Validators.required]);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.dob.setValidators([Validators.required]);
    }
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.dob.updateValueAndValidity();
    if (this.data[0] != undefined) {
      if (
        this.data[0].supplierCode == "airasia" ||
        this.data[0].supplierCode == "airasia_v2"
      ) {
        this.travellerForm.controls.passengerList["controls"][
          i
        ].controls.nationality.setValidators([Validators.required]);
      }
    }
    if(this.selectedFare && this.selectedFare[0] && this.selectedFare[0].supplierInfo && this.selectedFare[0].supplierInfo.subPriceType){
      if(this.selectedFare[0].supplierInfo && this.selectedFare[0].supplierInfo.subPriceType == 'STUD'){
        this.travellerForm.controls.passengerList["controls"][i].controls.studentId.setValidators([Validators.required]);
        this.isStudentFare = true
      }
      if(this.selectedFare[0].supplierInfo && this.selectedFare[0].supplierInfo.subPriceType == 'SRCT'){
        this.travellerForm.controls.passengerList["controls"][i].controls.seniorCitizenId.setValidators([Validators.required]);
        this.travellerForm.controls.passengerList["controls"][i].controls.dob.setValidators([Validators.required]);
        this.isSeniorCitizenFare = true
        this.maxDobDate["Adult"] =  new Date(moment().subtract(60, 'years'))
        this.minDobDate["Adult"] =  new Date(moment().subtract(200, 'years'))
      }
    }
    this.travellerForm.updateValueAndValidity();
  }

  // function to bind destination details
  getTravellers(val) {
    val = val.currentTarget.value;
    let valueLength  = 3
    if(this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')){
      valueLength = 2;
    }
    if (val.length >= valueLength) {
      this.travellerService
        .viewAllTravellersForBooking(val)
        .subscribe((res) => {
          let data: any;
          data = res;
          // let uniqueArr = [];
          // data.forEach(el => {
          // 	let filteredArr = uniqueArr.filter((v) => {
          // 		if (v.firstname == el.firstname) {
          // 			return v;
          // 		}
          // 	})
          // 	if (filteredArr.length == 0) {
          // 		uniqueArr.push(el)
          // 	}
          // });
          this.passengerDetails = data;
        });
    }
  }
  checkifExist(values) {
    let tArr;
    this.travellerService.checkifExist(values).subscribe((res) => {
      tArr = res;
      if (tArr.length == 0) {
        this.travellerService.saveAllTraveller(values).subscribe((res) => {
          console.log("saved", res);
        });
      } else {
        this.travellerService.updateTraveller(values).subscribe((res) => {
          console.log("updated", res);
        });
      }
    });
  }

  // dummy data for paxes
  bindDummyAdultArr(value) {
    this.dummyAdultNameArr = [];
    this.travellerForm.controls.passengerList.value.forEach((el) => {
      if (el.passengerType == "Adult") {
        this.dummyAdultNameArr.push(el.firstName);
      }
    });
  }

  // function to set pax details
  setPaxDetails(values, i) {
    this.paxSubmitted = false;
    if (i == 0 && values.mobile != null && values.mobile != "") {
      this.travellerForm.controls.mobile.setValue(values.mobile);
    }
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.firstName.setValue(values.firstname);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.lastName.setValue(values.lastname);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.title.setValue(values.title);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.gender.setValue(values.gender);
    if (!this.isDomestic) {
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.passportNo.setValue(values.passport_no);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.nationality.setValue(values.nationality);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.issuingCountry.setValue(values.issuing_country);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.expiryDate.setValue(values.expiry_date);
      this.travellerForm.controls.passengerList["controls"][
        i
      ].controls.dob.setValue(values.dob);
    }
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.seatPrefences.setValue(values.seat_prefences);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.specialServices.setValue(values.special_services);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.mealServices.setValue(values.meal_prefences);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.baggageServices.setValue(values.baggage_services);
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.travellerId.setValue(values.id);
    this.bindDummyAdultArr("");
    this.passengerDetails = [];
  }

  // function to get all countries
  getNationality() {
    this.affiliateService.getCountries().subscribe((res) => {
      this.nationalityArr = JSON.parse(res["_body"]);
     
      let activeSearchRequest = JSON.parse(sessionStorage.getItem("ActiveSearchRequest"))
      let isDomestic = activeSearchRequest.domestic;
      
      if(!isDomestic) {
        for(let i = 0; i < this.DynamicFormControls.controls.length; i++) {
            this.DynamicFormControls.controls[i]['controls']['issuingCountry'].setValue('India')
            this.DynamicFormControls.controls[i]['controls']['nationality'].setValue('India')
        }
      }
      
    });

   
    
  }

  // function to bind traveller first name
  bindTravellerFirstName(e, i, type, passenger) {
    // if (type == 'Infant') {
    // 	this.bindDummyAdultArr(e.target.value)
    // }
    this.travellerForm.controls.passengerList["controls"][
      i
    ].controls.firstName.setValue(e.target.value);
    if (e.currentTarget.value.length > 2 && e.key == "Tab") {
      if (passenger.length > 0 && passenger != undefined) {
        this.setPaxDetails(passenger[0], i);
      }
    }
    if (type == "Adult") {
      this.bindDummyAdultArr(e.target.value);
    }
  }

  showHoldInfoFn(values, forceBooking, checkDuplicate, isHold) {
    if( 
      this.supplierName.includes('AMADEUS') && this.selectedSeatArr && this.selectedSeatArr.length>0 && 
      (
        this.selectedSeatArr[0].seats.length> 0 ||
        this.selectedSeatArr[0].baggage.length> 0 ||
        this.selectedSeatArr[0].additionalBaggages.length> 0 ||
        this.selectedSeatArr[0].ancillaries.length> 0 || 
        this.selectedSeatArr[0].meals.length> 0 ||
        this.selectedSeatArr[0].wheelChairs.length> 0
      )
    ) {
      this.ssrWarningMessage = true;
    } else {
      this.ssrWarningMessage = false;
    }

    if (!this.isLCC) {
      this.openHoldInfoModal();
    } else {
      this.issueTicket(values, forceBooking, checkDuplicate, isHold);
    }
  }

  setAllowHold(event) {
    if (event.target.checked == true) {
      this.allowHold = true;
    } else {
      this.allowHold = false;
    }
  }

  // function to issue ticket live booking or local booking as well
  async issueTicket(values, forceBooking, checkDuplicate, isHold) {

    // 
    values.hideFaresOnAirlineTicket = this.travellerForm.value.isMaskFareEnabled  
    values.isInsuranceRequested = this.isInsuranceSelected
    values.insuranceTravellerList= this.insuranceTravellerList
    this.data[0].isInsuranceSelected = this.isInsuranceSelected
    this.isIncreasedPrice = false;
    this.messageList["messageList"] = [];
    // this.ngRedux.dispatch(SetGlobalMessage(this.messageList))
    this.notificationService.showMessage(this.messageList);
    values.userDetails = JSON.parse(localStorage.getItem("user_details"));
    console.log(
      "Passenger List",
      this.travellerForm.controls.passengerList.value
    );
    this.active = true;
    this.disableBtn = true;
    values.travellers = this.travellerForm.controls.passengerList.value;
    const invalid = [];
    const controls = this.travellerForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        if(name == 'passengerList'){
          this.paxSubmitted = true
          this.setStep(this.sectionIndex.TRAVELLER_INFO, null);
          break;
        }else{
          this.contactSubmitted = true
          this.setStep(this.sectionIndex.PERSONAL_INFO, null)
          break;
        }
      }
    }
    if (invalid.length > 0) {
      this.panelOpenState = true;
      this.openTravellerTab = true;
      this.active = false;
      return false;
    }

    if (
      this.invalidCredit &&
      this.selectedPaymentMethod == "agent-balance" &&
      !isHold
    ) {
      this.active = false;
      return false;
    }
    if (this.fareType == "SME" && this.data[0].supplierCode != "AMADEUS_CORPORATE" && this.data[0].supplierCode != "6E" && this.data[0].supplierCode != "6E_SME" && this.data[0].supplierCode != "6E_T") {
      if (
        values.corporateName.trim() == "" ||
        values.corporateEmail.trim() == "" ||
        values.corporateGstNumber.trim() == "" ||
        values.corporateMobile.trim() == ""
      ) {
        this.setStep(this.sectionIndex.GST_DETAILS, null);
        this.showError("warning-box", "Please Add GST Details", undefined);
        this.active = false;
        return false;
      }
    }
    if (this.data[0].priceType == "SPICEMAX") {
      if (this.selectedSeatArr == undefined) {
        this.setStep(this.sectionIndex.ADD_ONS, null);
        this.showError(
          "warning-box",
          'Seat is mandatory with spicemax fare',
          undefined
        );
        this.active = false;
        this.warningMessage = "Seat is mandatory with spicemax fare";
        return false;
      } else if (this.selectedSeatArr[0].seats.length == 0) {
        this.setStep(this.sectionIndex.ADD_ONS, null);
        this.active = false;
        this.warningMessage = "Seat is mandatory with spicemax fare";
        return false;
      } else {
        this.warningMessage = "";
      }
    } else {
      this.warningMessage = "";
    }
    if(this.hasFreeMeal){
      let selectMealPerLegsCount = 0
      if(this.selectedMealArr){
        for (let selectedSSR of this.selectedMealArr) {
            if(selectedSSR.meals && selectedSSR.meals.length){
              let mealCountPerTraveller = 0
							for (const meal of selectedSSR.meals) {
								if(meal && meal.code && meal.price >= 0){
									mealCountPerTraveller++
								}
							}
							if(mealCountPerTraveller == (this.noOfAdult+this.noOfChild)){
								selectMealPerLegsCount++
							}
            }
                
        }
      }
      if(selectMealPerLegsCount < this.totalFreeMealPerLeg && this.supplierName != "AMADEUS_V4" && this.supplierName != "AMADEUS_STUD" && this.supplierName != "VERTEIL" && this.supplierName != "SABRE"){
        this.setStep(this.sectionIndex.ADD_ONS, null);
        this.activeAddonTab = 'meal'
        this.warningMessage = "Please select free meal for all sector and traveller";
        this.warningMessageType = 'danger'
        this.showError(
          "error-box",
          this.warningMessage,
          undefined
        );
        this.active = false;
        return false
      }else{
				this.warningMessage = "";
				this.warningMessageType = ''
			}
    }
    values.isHold = isHold;
    this.isHoldCurrentBooking = isHold;

    values.passengerName =
      values.passengerList[0].firstName +
      " " +
      values.passengerList[0].lastName;
    values.bookingDate = new Date();
    values.gstId = this.gstId;
    if (this.isDomestic == true) {
      values.isDomestic = "true";
    } else {
      values.isDomestic = "false";
    }
    values.sCode = "";
    values.seats = this.selectedSeatArr;
    values.baggage = this.selectedBaggageArr;
    values.additionalBaggage = this.selectedAdditionalBaggageArr;
    values.ancillary = this.selectedAncillaryArr;
    values.meals = this.selectedMealArr;
    values.wheelChairs = this.selectedWheelChairArr;

    values.xlCode = JSON.parse(localStorage.getItem("user_details"))[
      "xlAccountCode"
    ];
    values.passengerList = this.createPassengerData(
      values.passengerList,
      "TICKET-"
    ); // create passenger/pax data and added dynamic tickets for per pax
    let bookingRes = {};
    this.data[0]["netPayable"] = this.netPayable;
    localStorage.setItem("formValue", JSON.stringify(values));
    this.isDuplicate = false;
    if (checkDuplicate) {
      let duplicateBookingRes = {};
      // if (environment.enableBooking) {
      // 	duplicateBookingRes = await this.doDuplicateBookingCheck(values); // calling to create an live booking
      // }
      if (environment.enableBooking && this.bookingIssueInprogress == false) {
        this.bookingIssueInprogress = true;
        duplicateBookingRes = await this.doDuplicateBookingCheck(values);
        this.bookingIssueInprogress = false; // calling to create an live booking
      }
      console.log("Duplicate booking check  response", duplicateBookingRes);
      if (duplicateBookingRes.hasOwnProperty("duplicate")) {
        if (duplicateBookingRes["duplicate"]) {
          this.duplicateBookingMsg = {
            text: duplicateBookingRes["errorMessage"],
            reference_no: duplicateBookingRes["duplicateReference"],
            direction: "outbound",
            isHold: false,
          };
          console.log("duplicateBookingMsg : ", this.duplicateBookingMsg);
          this.active = false;
          this.isDuplicate = true;
          this.bookingIssueInprogress = false;
          this.disableBtn = false;
          this.openDuplicateBookingModal();
          return false;
        } else {
          this.bookingIssueInprogress = false;
          if (this.selectedPaymentMethod == "agent-balance") {
            this.doBooking(values);
          } else {
            this.active = false;
            this.openPaymentInfoModal();
          }
        }
      }
    } else {
      this.isDuplicate = false;
      values["forceBooking"] = forceBooking;
      if (forceBooking) {
        if (
          this.duplicateBookingMsg != undefined &&
          this.duplicateBookingMsg != null &&
          this.duplicateBookingMsg.hasOwnProperty("reference_no")
        ) {
          values["duplicateReference"] =
            this.duplicateBookingMsg["reference_no"];
        }
      }
      localStorage.setItem("formValue", JSON.stringify(values));
      if (this.selectedPaymentMethod == "agent-balance") {
        this.doBooking(values);
      } else {
        this.active = false;
        this.openPaymentInfoModal();
      }
    }
  }

  async doBooking(values) {
    let bookingRes = {};
    // if (environment.enableBooking) {
    // 	bookingRes = await this.creatingBookingRequest(values); // calling to create an live booking
    // }
    if (this.bookingIssueInprogress == true) {
      return false;
    }
    if (environment.enableBooking && this.bookingIssueInprogress == false) {
      this.bookingIssueInprogress = true;
      bookingRes = await this.creatingBookingRequest(values); // calling to create an live booking
      this.bookingIssueInprogress = false;
    }
    console.log("Booking response", bookingRes);
    if (bookingRes.hasOwnProperty("duplicate")) {
      if (bookingRes["duplicate"] == true) {
        this.duplicateBookingMsg = {
          text: bookingRes["errorMessage"],
          reference_no: bookingRes["duplicateReference"],
          direction: "outbound",
          isHold: values.isHold,
          status: bookingRes["status"],
        };
        this.active = false;
        this.bookingIssueInprogress = true;
        this.disableBtn = false;
        this.openDuplicateBookingModal();
        return false;
      }
    } else if (
      bookingRes.hasOwnProperty("priceChanged") &&
      bookingRes["priceChanged"]
    ) {
      this.data[0].fares = [];
      this.data[0].fares.push(
        bookingRes["bookingResponse"].selectFlight.selectedFare
      );
      this.data[0].selectedFare =
        bookingRes["bookingResponse"].selectFlight.selectedFare;
      this.data[0]["oldFare"] =
        bookingRes["bookingResponse"].selectFlight.oldFare;

      if (
        bookingRes["bookingResponse"]["selectFlight"].hasOwnProperty(
          "segments"
        ) &&
        bookingRes["bookingResponse"]["selectFlight"]["length"] > 0
      ) {
        this.data[0].segments =
          bookingRes["bookingResponse"]["selectFlight"]["segments"];
      }
      // fares
      localStorage.setItem("selectedFlight", JSON.stringify(this.data[0]));
      this.isIncreasedPrice = true;
      this.active = false;
      // this.oldTtotalNetPayable = 5000;
      this.loadFareData();
    } else if (
      bookingRes.hasOwnProperty("isRevertibleException") &&
      bookingRes["isRevertibleException"]
    ) {
      this.active = false;
      this.showError(
        "error-box",
        bookingRes["message"],
        bookingRes["messageDetails"]
      );
    } else {
      localStorage.removeItem("formValue");
      // return false
      this.data[0]["bookingId"] = bookingRes["bookingId"];
      if(this.groupFareReceived){
        this.commonService.putAdminCall(`affiliate/updateGroupCounters/${values.xlCode}/booking`,{}).subscribe(res=> {
					console.log(res)
				},err=> {
					
				})
      }
      if (bookingRes != "" && bookingRes["bookingResponse"] != null) {
        //Save Booking in postgres sql
        this.data[0]["status"] = "TICKETED";
        this.data[0]["bookingId"] = bookingRes["bookingResponse"]["bookingId"];
        this.data[0]["invoice_no"] =
          bookingRes["bookingResponse"]["invoice_no"];
        if (bookingRes["bookingResponse"].hold == true) {
          this.data[0]["ticketedIssue"] = bookingRes["message"];
          this.data[0]["status"] = "HOLD";
          if(bookingRes["bookingResponse"]['selectFlight']['supplierInfo']) this.data[0]["hold_ttl"] = bookingRes["bookingResponse"]['selectFlight']['supplierInfo'].hold_ttl || "";
        }
        if(bookingRes && bookingRes['bookingResponse'] && bookingRes['bookingResponse']['status']){
					this.data[0]['status'] = bookingRes['bookingResponse']['status']
				}
        if (bookingRes.hasOwnProperty("supplierInfo")) {
          this.data[0]["supplierMessage"] = bookingRes["supplierInfo"];
        }

        if(bookingRes.hasOwnProperty("pnr")){
          this.data[0]["pnr"] = bookingRes["pnr"]
        }
        localStorage.setItem(
          "ONWARDS_BOOKING_" + this.data[0]["bookingId"],
          JSON.stringify(this.data[0])
        );
        if (bookingRes["bookingResponse"].hold == true) {
          this.saveBooking(values, "bookingJson", false);
        } else {
          this.saveBooking(values, "bookingJson", true);
        }
      } else {
        this.data[0]["status"] = "FAILED";
        localStorage.setItem(
          "ONWARDS_BOOKING_" + this.data[0]["bookingId"],
          JSON.stringify(this.data[0])
        );
        console.log("ONWARDS_BOOKING_" + this.data[0]["bookingId"]);
        this.saveBooking(values, "bookingJson", false);
      }
    }
  }

  async holdTicket(values, forceBooking, checkDuplicate) {
    if (this.bookingIssueInprogress == true) {
      return false;
    }
    this.active = true;
    this.disableBtn = true;
    const invalid = [];
    const controls = this.travellerForm.controls;
    for (const name in controls) {
      if (controls[name].invalid && name != "passengerList") {
        invalid.push(name);
      }
    }
    console.log("invalid field on submit", invalid);
    if (invalid.length > 0) {
      this.panelOpenState = true;
      this.openTravellerTab = true;
      this.setStep(this.sectionIndex.TRAVELLER_INFO, null);
      this.active = false;
      return false;
    }

    if (this.fareType == "SME") {
      if (
        values.corporateName == "" ||
        values.corporateEmail == "" ||
        values.corporateGstNumber == "" ||
        values.corporateMobile == ""
      ) {
        this.setStep(this.sectionIndex.GST_DETAILS, null);
        this.active = false;
        return false;
      }
    }

    if (this.data[0].priceType == "SPICEMAX") {
      if (this.selectedSeatArr == undefined) {
        this.setStep(this.sectionIndex.ADD_ONS, null);
        this.active = false;
        this.warningMessage = "Seat is mandatory with spicemax fare";
        return false;
      } else if (this.selectedSeatArr[0].seats.length == 0) {
        this.setStep(this.sectionIndex.ADD_ONS, null);
        this.active = false;
        this.warningMessage = "Seat is mandatory with spicemax fare";
        return false;
      } else {
        this.warningMessage = "";
      }
    } else {
      this.warningMessage = "";
    }
    if(this.hasFreeMeal){
      let hasSelected = false
      if(this.selectedMealArr){
        for (let selectedSSR of this.selectedMealArr) {
            if(selectedSSR.meals && selectedSSR.meals.length == (this.noOfAdult+this.noOfChild)){
                hasSelected = true
            }else{
                hasSelected = false
            }
                
        }
      }
      if(!hasSelected){
        this.warningMessage = "Please select free meal";
        this.warningMessageType = 'danger'
        this.setStep(this.sectionIndex.ADD_ONS, null);
        this.showError(
          "error-box",
          this.warningMessage,
          undefined
        );
        this.active = false;
        return false
      }
    }

   

    values.isHold = true;
    values["message"] = "Success";
    values.passengerName =
      values.passengerList[0].firstName +
      " " +
      values.passengerList[0].lastName;
    values.bookingDate = new Date();
    values.gstId = this.gstId;
    if (this.isDomestic == true) {
      values.isDomestic = "true";
    } else {
      values.isDomestic = "false";
    }
    values.sCode = "";
    values.seats = this.selectedSeatArr;
    values.baggage = this.selectedBaggageArr;
    values.additionalBaggage = this.selectedAdditionalBaggageArr;
    values.ancillary = this.selectedAncillaryArr;
    values.meals = this.selectedMealArr;
    values.wheelChairs = this.selectedWheelChairArr;

    values.xlCode = JSON.parse(localStorage.getItem("user_details"))[
      "xlAccountCode"
    ];
    values.passengerList = this.createPassengerData(
      values.passengerList,
      "TICKET-"
    ); // create passenger/pax data and added dynamic tickets for per pax
    let bookingRes = {};
    this.data[0]["netPayable"] = this.netPayable;
    localStorage.setItem("formValue", JSON.stringify(values));
    if (checkDuplicate == true) {
      let duplicateBookingRes = {};
      if (environment.enableBooking) {
        duplicateBookingRes = await this.doDuplicateBookingCheck(values); // calling to create an live booking
      }
      console.log("Duplicate booking check  response", duplicateBookingRes);
      if (duplicateBookingRes.hasOwnProperty("duplicate")) {
        if (duplicateBookingRes["duplicate"] == true) {
          this.duplicateBookingMsg = {
            text: duplicateBookingRes["errorMessage"],
            reference_no: duplicateBookingRes["duplicateReference"],
            direction: "outbound",
            isHold: true,
          };
          this.active = false;
          this.disableBtn = false;
          this.openDuplicateBookingModal();
          return false;
        } else {
          if (environment.enableBooking) {
            bookingRes = await this.creatingBookingRequest(values); // calling to create an live booking
          }
          this.data[0]["bookingId"] = bookingRes["bookingId"];
          if (bookingRes != "" && bookingRes["bookingResponse"] != null) {
            //Save Booking in postgres sql
            this.data[0]["status"] = "HOLD";
            this.data[0]["bookingId"] =
              bookingRes["bookingResponse"]["bookingId"];
            this.data[0]["invoice_no"] =
              bookingRes["bookingResponse"]["invoice_no"];
            localStorage.setItem(
              "ONWARDS_BOOKING_" + this.data[0]["bookingId"],
              JSON.stringify(this.data[0])
            );
            this.saveBooking(values, "bookingJson", false);
          } else {
            this.data[0]["status"] = "FAILED";
            // let bookingJson = this.bookingRequestService.getBookingRequest(values, this.request, this.supplierName,  this.isLCC);
            localStorage.setItem(
              "ONWARDS_BOOKING_" + this.data[0]["bookingId"],
              JSON.stringify(this.data[0])
            );
            console.log("ONWARDS_BOOKING_" + this.data[0]["bookingId"]);
            // bookingJson['failed_event'] = "TICKETED"
            this.saveBooking(values, "bookingJson", false);
          }
        }
      }
    } else {
      values["forceBooking"] = forceBooking;
      if (forceBooking) {
        values["duplicateReference"] = this.duplicateBookingMsg["reference_no"];
      }
      localStorage.setItem("formValue", JSON.stringify(values));
      if (environment.enableBooking && this.bookingIssueInprogress == false) {
        this.bookingIssueInprogress = true;
        bookingRes = await this.creatingBookingRequest(values); // calling to create an live booking
      }
      this.data[0]["bookingId"] = bookingRes["bookingId"];
      if (bookingRes != "" && bookingRes["bookingResponse"] != null) {
        //Save Booking in postgres sql
        this.data[0]["status"] = "HOLD";
        this.data[0]["bookingId"] = bookingRes["bookingResponse"]["bookingId"];
        this.data[0]["invoice_no"] =
          bookingRes["bookingResponse"]["invoice_no"];
        localStorage.setItem(
          "ONWARDS_BOOKING_" + this.data[0]["bookingId"],
          JSON.stringify(this.data[0])
        );
        this.saveBooking(values, "bookingJson", false);
      } else {
        this.data[0]["status"] = "FAILED";
        // let bookingJson = this.bookingRequestService.getBookingRequest(values, this.request, this.supplierName,  this.isLCC);
        localStorage.setItem(
          "ONWARDS_BOOKING_" + this.data[0]["bookingId"],
          JSON.stringify(this.data[0])
        );
        console.log("ONWARDS_BOOKING_" + this.data[0]["bookingId"]);
        // bookingJson['failed_event'] = "TICKETED"
        this.saveBooking(values, "bookingJson", false);
      }
    }
  }

  saveBooking(values, bookingJson, ledgerStatus) {
    // this.saveTravellers(values);
    this.bookingRequestService.checkifExistGST(values);
    let searchId = this.data[0]["bookingId"];
    this.active = false;
    this.router.navigate(["booking-status", { searchId }]);
  }
  saveTravellers(values) {
    values.userDetails = JSON.parse(localStorage.getItem("user_details"));
    values.travellers = this.travellerForm.controls.passengerList.value;
    for (let i = 0; i < values.travellers.length; i++) {
      const element = values.travellers[i];
      if (i == 0) {
        element["mobile_number"] = this.travellerForm.controls.mobile.value;
      } else {
        element["mobile_number"] = "";
      }
      this.checkifExist(element);
    }
  }

  // function to calculate duration between two datetime
  getDuration(dt1, dt2) {
    return this.commonService.timeDuration(dt2, dt1);
  }
  // function to trigger offer price
  viewOfffer() {
    if (this.isOffer) {
      this.isOffer = false;
    } else {
      this.isOffer = true;
    }
  }

  // tab click to select payment modes
  onLinkClick(event: MatTabChangeEvent) {
    if (event.index == 0) {
      if (this.creditRemaining < this.totalFare) {
        this.invalidCredit = true;
      } else {
        this.invalidCredit = false;
      }
    } else {
      this.invalidCredit = false;
    }
  }

  // function to bind step number for expansion panel
  async setStep(index: number, formValues) {
    this.step = index;
    window.scroll(0, 0);
    if(index == this.sectionIndex.ADD_ONS){
			this.toggleAddon = false
			this.toggleAddonSideBar()
		}else{
			this.toggleAddon = true
			this.toggleAddonSideBar()
		}
    if (index == this.sectionIndex.PERSONAL_INFO) { // VALIDATE TRAVELLERS DETAIL
      this.paxSubmitted = true;
      const invalid = [];
      const controls = this.travellerForm.controls.passengerList["controls"];
      for (let i = 0; i < controls.length; i++) {
        for (const name in controls[i].controls) {
          if (controls[i].controls[name].invalid) {
            invalid.push(name);
          }
        }
      }
      if (invalid.length > 0) {
        this.setStep(this.sectionIndex.TRAVELLER_INFO, null);
      } else{
      this.setPersonalDetails();
      let requestData = await this.bookingRequest.bookingRequest(
        this.travellerForm.value,
        this.data,
        this.limitType,
        true,
        this.subFareType
      );
      this.initializeSelectedSsr(requestData) // to set blank value and with travellers name
      let fullArr: any = [];
      const lThis = this;
      let allowSSRRequest= true
      // if ((!this.isLCC || this.supplierName == "AMADEUS_V4") && this.isSeatAvailabilityCall) {
        if ((this.isLCC  || this.supplierName.includes('AMADEUS') || this.supplierName.includes('VERTEIL') || this.supplierName == 'EMIRATES' || this.supplierName == "SABRE") && this.isSeatAvailabilityCall && allowSSRRequest) {
          this.isSeatAvailabilityCall = false;
          this.commonService.getmeal("ssrAvailability", requestData).subscribe(
            (res) => {
              fullArr = res;
              this.xmlSearchSsrLogsKey = res &&  res['legs'] && res['legs'].length > 0 && res['legs'][0]['supplierInfo'] && res['legs'][0]['supplierInfo']['BOOKING_REF_TS_KEY_FOR_SSR_LOGS'] ? res['legs'][0]['supplierInfo']['BOOKING_REF_TS_KEY_FOR_SSR_LOGS'] : ""
              let legs = [];
              // this.airlineCode
              if (
                fullArr && fullArr["length"] > 0 &&
                fullArr["legs"][0].hasOwnProperty("infant")
              ) {
                if (fullArr.legs[0].infant != null) {
                  let updateInfantPrice = fullArr.legs[0].infant.newPrice;
                  if (
                    updateInfantPrice !=
                    lThis.data[0].fares[0].travellerFares["INF"]["total"]
                  ) {
                    this.isIncreaseInfantPrice = false;
                    this.currentInfantPrice = 0;
                    this.increasedInfantPrice = 0;

                    lThis.isIncreaseInfantPrice = true;
                    lThis.currentInfantPrice = fullArr.legs[0].infant.oldPrice;
                    lThis.increasedInfantPrice = updateInfantPrice;
                    lThis.infTotalFare = updateInfantPrice;
                    lThis.infTaxFare = updateInfantPrice;
                    try {


          
                      if (
                        fullArr.legs[0].infant.hasOwnProperty(
                          "actualCurrencyValue"

                        )
                      ) {
                        let obj = {
                          actualCurrencyValue:
                            fullArr.legs[0].infant["actualCurrencyValue"],
                        };
                        lThis.data[0].fares[0].travellerFares["INF"][
                          "supplierInfo"
                        ] = obj;

                      }
                    } catch (error) {
                      console.log(error);
                    }
                    let infantPriceDifference =
                      updateInfantPrice - lThis.currentInfantPrice;
                    lThis.data[0].fares[0].travellerFares["INF"]["total"] =
                      updateInfantPrice;
                    lThis.data[0].fares[0].travellerFares["INF"][
                      "fareComponents"
                    ]["OT"] = updateInfantPrice;
                    if (
                      lThis.data[0].fares[0].fareComponents.hasOwnProperty(
                        "NET_PAYABLE"
                      )
                    ) {
                      lThis.data[0].fares[0].fareComponents.NET_PAYABLE =
                        lThis.data[0].fares[0].fareComponents.NET_PAYABLE +
                        infantPriceDifference;

                    }
                    lThis.data[0].fares[0].total =
                      lThis.data[0].fares[0].total + infantPriceDifference;
                    this.totalFare = lThis.data[0].fares[0].total;
                    localStorage.setItem(
                      "selectedFlight",
                      JSON.stringify(lThis.data[0])
                    );
                    lThis.calculateNetPayable(false);

                  }
                }
              }
              this.totalFreeMealPerLeg = 0
              for (let i = 0; i < fullArr["legs"].length; i++) {
                const meals = fullArr["legs"][i].meals ?  Object.values(fullArr["legs"][i].meals) : [];
                let hasFreeMealPerLeg = false
                for (let j = 0; j < meals.length; j++) {
                  const element = meals[j];
                  element["status"] = "notselected";
                  const el = element["fare"];
                  if (el.hasOwnProperty("base")) {
                    element["totalFare"] = Number(el.base);
                  } else {
                    element["totalFare"] = 0;
                  }
                  if (element["totalFare"] == 0) {
                    this.hasFreeMeal = true;
                    hasFreeMealPerLeg = true
                    this.warningMessage = 'Free Meal available.'
                  }
                }
                if(hasFreeMealPerLeg){
                  this.totalFreeMealPerLeg++
                }
                const baggages = fullArr["legs"][i].baggages ?  Object.values(fullArr["legs"][i].baggages) : [];
                for (let j = 0; j < baggages.length; j++) {
                  const element = baggages[j];
                  element["status"] = "notselected";
                  const el = element["fare"];

                  if (el.hasOwnProperty("base")) {
                    element["totalFare"] = Number(el.base);
                  } else {
                    element["totalFare"] = 0;
                  }
                  if (element["totalFare"] == 0) {
                    this.hasFreeBaggage = true;
                    this.warningMessage = 'Free Baggage available.'
                    if(this.hasFreeMeal) {
                      this.warningMessage = 'Free Meal & Baggage available.'
                    }
                  }
                }
                const ancillaries = fullArr["legs"][i].ancillaries ?  Object.values(fullArr["legs"][i].ancillaries) : [];
                for (let j = 0; j < ancillaries.length; j++) {
                  var element = ancillaries[j];
                  element["status"] = "notselected";
                  var el = element["fare"];

                    if ( el && el.hasOwnProperty("base")) {
                      element["totalFare"] = Number(el.base);
                    } else {
                      element["fare"] = {
                        base : 0,
                        tax : 0
                      }
                      element["totalFare"] = 0;
                    }
                }

                let wheelChairs:any = []
                if(fullArr["legs"][i].wheelChairs){
                  wheelChairs = Object.values(fullArr["legs"][i].wheelChairs);
                  for (let j = 0; j < wheelChairs.length; j++) {
                    const element = wheelChairs[j];
                    element["status"] = "notselected";
                    const el = element["fare"];

                    if (el.hasOwnProperty("base")) {
                      element["totalFare"] = Number(el.base);
                    } else {
                      element["totalFare"] = 0;
                    }
                  
                  }
                }
                  

                let baggageAfterSorting = baggages.sort(function (a, b) {
                  return a["totalFare"] - b["totalFare"];
                });  
                let obj = {};
                obj["meals"] = meals;
                obj["baggages"] = baggageAfterSorting;
                obj["ancillaries"] = ancillaries;
                obj["arrival"] = fullArr["legs"][i].arrival;
                obj["wheelChairs"] = wheelChairs
                obj["totalSelected"] = 0;
                obj["travellerCount"] = requestData.travellers.length;
                obj["flightInfo"] = requestData;
                let tempTravellerArr = [];
                for (let k = 0; k < requestData.travellers.length; k++) {
                  let tempTraveller = {};
                  tempTraveller = Object.assign({}, requestData.travellers[k]);
                  tempTravellerArr.push(tempTraveller);
                }
                obj["travellers"] = tempTravellerArr;
                obj["departure"] = fullArr["legs"][i].departure;
                obj["flightCode"] = fullArr["legs"][i].airline;
                obj["flightNo"] = fullArr["legs"][i].flightNo;
                legs.push(obj);
              }
              this.ssrAvailability = legs;

              if (this.ssrAvailability.length == 0) {
                this.showloadError = true;
                this.showloadErrorMessage = 'Unable To Fetch SSRs'
              }
              this.checkSSRAvailability()

              this.isSeatAvailabilityCall = true;
              console.log("Meal Array", this.ssrAvailability);
            },
            (error) => {
              this.isSeatAvailabilityCall = true;
              this.showloadError = true;
              this.showloadErrorMessage = error.error ? error.error : 'Unable To Fetch SSRs'
              console.log("Fetch Error", error);
            }
          );

            this.commonService.getSeat("seatMap", requestData).subscribe(
              (res) => {
                
                let seatMap = res;
                this.xmlSearchSeatLogsKey = res &&  res['leg'] && res['leg'].length > 0 && res['leg'][0]['supplierInfo'] && res['leg'][0]['supplierInfo']['BOOKING_REF_TS_KEY_FOR_SEAT_LOGS'] ? res['leg'][0]['supplierInfo']['BOOKING_REF_TS_KEY_FOR_SEAT_LOGS'] : ""
                let legs = [];
                for (let i = 0; i < seatMap["leg"].length; i++) {
                  if (seatMap["leg"][i].seats.rows != null) {
                    const values = Object.values(seatMap["leg"][i].seats.rows);
                    for (let j = 0; j < values.length; j++) {
                      const element = values[j];
                      let charges = Object.values(element);
                      for (let k = 0; k < charges.length; k++) {
                        const ch = charges[k];
                        ch["totalFare"] = 0;
                        ch["status"] = "notselected";
                        const el = ch.charges;
                        if (el.hasOwnProperty("base")) {
                          ch["totalFare"] = Number(el.base);
                        } else {  
                          ch["totalFare"] = 0;
                        }
                      }
                    }
                    let obj = {};
                    obj["keys"] = Object.keys(seatMap["leg"][i].seats.rows);
                    obj["values"] = values;
                    obj['cabinInfo'] = seatMap["leg"][i].seats.cabinInfo ? seatMap["leg"][i].seats.cabinInfo : null
                    // obj['cabinInfo'] = [
                    //   {"row" : "A","windowSeat":true,"aisleSeat" : false,"centerSeat":false},
                    //   {"row" : "B","windowSeat":false,"aisleSeat" : false,"centerSeat":true},
                    //   {"row" : "C","windowSeat":false,"aisleSeat" : true,"centerSeat":false},
                    //   {"row" : "D","windowSeat":false,"aisleSeat" : true,"centerSeat":false},
                    //   {"row" : "E","windowSeat":false,"aisleSeat" : false,"centerSeat":true},
                    //   {"row" : "F","windowSeat":false,"aisleSeat" : false,"centerSeat":true},
                    //   {"row" : "G","windowSeat":false,"aisleSeat" : true,"centerSeat":false},
                    //   {"row" : "H","windowSeat":false,"aisleSeat" : true,"centerSeat":false},
                    //   {"row" : "J","windowSeat":false,"aisleSeat" : false,"centerSeat":true},
                    //   {"row" : "K","windowSeat":true,"aisleSeat" : false,"centerSeat":false},
                    // ]
                    obj["arrival"] = seatMap["leg"][i].arrival;
                    obj["totalSelected"] = 0;
                    obj["travellerCount"] = requestData.travellers.length;
                    let tempTravellerArr = [];
                    let associateTravellerInfoMap = new Map();
                    for (let k = 0; k < requestData.travellers.length; k++) {
                      let tempTraveller = {};
                      tempTraveller = Object.assign(
                        {},
                        requestData.travellers[k]
                      );
                      tempTravellerArr.push(tempTraveller);
                      if (
                        this.travellerForm.controls.passengerList["controls"][k]
                          .controls.passengerType.value == "Infant"
                      ) {
                        associateTravellerInfoMap.set(
                          this.travellerForm.controls.passengerList["controls"][k]
                            .controls.associateInfo.value,
                          ""
                        );
                      }
                    }
                    obj["associateTravellerInfoMap"] = associateTravellerInfoMap;
                    obj["travellers"] = tempTravellerArr;
                    obj["flightInfo"] = requestData;
                    obj["departure"] = seatMap["leg"][i].departure;
                    obj["flightCode"] = seatMap["leg"][i].flightCode;
                    obj["flightNo"] = seatMap["leg"][i].flightNo;
                    legs.push(obj);
                  }
                }
                this.seatMapArr = legs;
                console.log("Seat Map array : ", this.seatMapArr);
                this.isSeatAvailabilityCall = true;
                if(this.seatMapArr.length==0){
                  this.showseatloadError = true
                  this.showseatloadErrorMessage =  "Unable To Fetch Seats Data"
                }
                console.log('Seat Map', this.seatMapArr);
              },
              (error) => {
                this.isSeatAvailabilityCall = true;
                this.showseatloadError = true
                this.showseatloadErrorMessage =  error.error ? error.error : 'Unable To Fetch Seats Data'
                console.log("Fetch Error", error);
              }
            );
          }
      }
    } else if (index == this.sectionIndex.GST_DETAILS) {
      const invalid = [];
      this.contactSubmitted = true;
      const controls = this.travellerForm.controls;
      for (const name in controls) {
        if (controls[name].invalid && name != "passengerList") {
          invalid.push(name);
        }
      }
      console.log("invalid field in step 4", invalid);
      if (invalid.length > 0 && !invalid.includes("corporateEmail")) {
        this.setStep(this.sectionIndex.TRAVELLER_INFO, null);
      }
    }
    if (index == this.sectionIndex.TRAVEL_INSURANCE) {
      const invalid = [];
      this.contactSubmitted = true;
      const controls = this.travellerForm.controls;
      for (const name in controls) {
        if (controls[name].invalid && name != "passengerList") {
          invalid.push(name);
        }
      }
      console.log("invalid field in step 5", invalid);
      if (invalid.length > 0 && invalid.includes("corporateEmail")) {
        this.setStep(this.sectionIndex.GST_DETAILS, null);
      }
    }

    if (index == this.sectionIndex.PAYMENT) {
      try {
        if (
          this.selectedMealArr == undefined ||
          this.selectedMealArr[0].meals.length > 0
        ) {
          this.isSelectedSMEMeals = false;
        }
      } catch (error) {}
      this.showSelectedSeatError = false;
      try {
        if (
          this.selectedSeatArr != undefined &&
          this.seatMapArr &&
          this.seatMapArr.length > 0
        ) {
          for (const key in this.seatMapArr) {
            if (
              Object.prototype.hasOwnProperty.call(this.selectedSeatArr, key)
            ) {
              const selectedSeat = this.selectedSeatArr[key];
              if (selectedSeat) {
                if (
                  selectedSeat.seats == null ||
                  (selectedSeat.seats &&
                    selectedSeat.seats.length > 0 &&
                    selectedSeat.seats.length !=
                      this.noOfAdult + this.noOfChild)
                ) {
                  this.showSelectedSeatError = true;
                  this.setStep(this.sectionIndex.ADD_ONS,null)
                  return false;
                }
              }
            }
          }
        }
      } catch (error) {}
    }
  }

  checkSSRAvailability(){
    let hasMeal = false
    let hasSeat = false
    let hasBaggage = false
    let hasAncillary = false
    if(this.ssrAvailability && this.ssrAvailability.length > 0){
      for (const ssrItem of this.ssrAvailability) {
        if(ssrItem && ssrItem.seats && ssrItem.seats.length > 0) hasSeat = true;
        if(ssrItem && ssrItem.meals && ssrItem.meals.length > 0) hasMeal = true;
        if(ssrItem && ssrItem.baggages && ssrItem.baggages.length > 0) hasBaggage = true;
        if(ssrItem && ssrItem.ancillaries && ssrItem.ancillaries.length > 0) hasAncillary = true;
      }
    }
    this.ssrAvailabilityStatus = {
      hasMeal,hasSeat, hasBaggage, hasAncillary
    }
  }

  //funtion to check duplicate booking
  doDuplicateBookingCheck(data) {
    return new Promise((resolve, reject) => {
      let obj = this.bookingRequest.bookingRequest(
        data,
        this.data,
        this.limitType,
        false,
        this.subFareType,
        this.xmlSearchSsrLogsKey,
        this.xmlSearchSeatLogsKey
      );
	   
      this.request = obj;
      this.postService
        .postMethod(`${environment.url}/book/checkduplicate`, obj)
        .subscribe(
          (res) => {
            if (res != "") {
              resolve(res);
            } else {
              resolve("");
            }
          },
          (err) => {
            console.log("Error in booking : ", err);
            if (err.hasOwnProperty("error")) {
              if (err.error.hasOwnProperty("duplicate")) {
                resolve(err.error);
              }
            } else {
              resolve("");
            }
          }
        );
    });
  }
  // fucntion to trigger live booking request
  creatingBookingRequest(data) {
    return new Promise((resolve, reject) => {
      data['total_flight_count']=1
      data['current_flight_index']=1
      let obj = this.bookingRequest.bookingRequest(
        data,
        this.data,
        this.limitType,
        false,
        this.subFareType,
        this.xmlSearchSsrLogsKey,
        this.xmlSearchSeatLogsKey
      );
      this.request = obj;
      // let mockUl = `https://c8ba32cd-3151-4106-8761-c5c4a95e258b.mock.pstmn.io/failBaggage`;
      // this.postService.postMethod(`${mockUl}`, obj).subscribe(res => {
      if(environment.name == 'prod'){
        try {
          let type = 'new_booking'
          let message = `New booking request.`
          if(this.request.hideFaresOnAirlineTicket == true){
            type = 'fare_masking'
            message = 'New booking request with fare masking'
          }
          if(this.request.hold == true){
            type = `hold_booking`
            message = 'Hold booking request.'
            if(type == 'fare_masking') {
              type = `hold_fare_masking`
              message = 'New hold booking request with fare masking'
            }
          }
          if(data.isInsuranceRequested){
            type += `_with_insurance`;
          }
          this.commonService.addUserBookingActivity(type,message,"","")
        } catch (error) {
          
        }
      }
      this.postService
        .postMethod(`${environment.url}/book/test`, obj)
        .subscribe(
          (res) => {
            if (res != "") {
              resolve(res);
            } else {
              resolve("");
            }
          },
          (err) => {
            console.log("Error in booking : ", err);
            resolve(err["error"]);
          }
        );
    });
  }
  // function to create passenger data with ticket number
  createPassengerData(passengerList, ticketNo) {
    return passengerList.map((v, i) => {
      let count = i + 1;
      v.ticketNo = ticketNo + count;
      return v;
    });
  }

  // function to create pax details for after booking request

  onFinished() {
    let searchId = localStorage.getItem("flightSearchId");
    this.router.navigate(["pages/flight", { searchId }]);
  }

  bindGSTInfo(val) {
    val = val.currentTarget.value;
    if (val.length >= 3) {
      this.travellerService.viewAllGSTForBooking(val).subscribe((res) => {
        let data = res;
        if (data["length"] > 0) {
          let uniqueArr = [];
          for (let i = 0; i < data["length"]; i++) {
            let el = data[i];
            let filteredArr = uniqueArr.filter((v) => {
              if (v.name == el.name) {
                return v;
              }
            });
            if (filteredArr.length == 0) {
              uniqueArr.push(el);
            }
          }
          this.gstArr = uniqueArr;
        }
      });
    }
  }
  bindTravellerGstName(e) {
    this.travellerForm.controls.corporateName.setValue(e.target.value);
  }
  setGST(values) {
    if (values != null && values != undefined) {
      this.setGstDetails(values);
      setTimeout(() => {
        this.closeactiveModal();
      }, 100);
    }
  }
  private closeactiveModal(): void {
    this.closeactiveBtn.nativeElement.click();
  }
  private openPaymentInfoModal(): void {
    this.showPaymentInfo.nativeElement.click();
  }
  private openHoldInfoModal(): void {
    this.showHoldInfo.nativeElement.click();
  }
  private openDuplicateBookingModal(): void {
    this.showDuplicateBooking.nativeElement.click();
  }
  private openPriceChangeModal(): void {
    this.showPriceChange.nativeElement.click();
  }

  private opengroupFareModal(): void {
    this.showGroupfareModal.nativeElement.click();
  }
  setGstDetails(values) {
    this.travellerForm.controls.corporateName.setValue(values.name);
    this.travellerForm.controls.corporateGstNumber.setValue(values.gst_number);
    this.travellerForm.controls.corporateMobile.setValue(values.mobile);
    this.travellerForm.controls.corporateEmail.setValue(values.email);
    this.gstId = values.id;
  }
  openSearch() {
    let searchId = localStorage.searchId;
    // window.location("/#/pages/flight;searchId="+searchId);
    this.router.navigate(["/pages/flight", { searchId }]);
  }
  etCheck(event) {
    if (event.target.checked == true) {
      this.enableBooking = true;
      this.bookingErrorMsg = false;
    } else {
      this.enableBooking = false;
      this.bookingErrorMsg = true;
    }
  }

  //Payment Gateway

  setPaymentMethod(paymentMethod, checkNetPayable,checkHold) {
    this.selectedPaymentMethod = paymentMethod;
    console.log("Payment method : ", paymentMethod);
    if (paymentMethod != "agent-balance") {
      this.showHold = false;
    } else {
      if(checkHold){
        this.checkDateDiff()
        if (
          this.data[0].supplierCode == "airasia" ||
          this.data[0].supplierCode == "airasia_v2" || 
          this.data[0].airline.code == "QP"
        ) {
          this.showHold = false;
        }
      }else{
        this.showHold = true;
      }

    }
    if (checkNetPayable == true) {
      this.calculateNetPayable(false);
    }
  }

  async checkDuplicateBooking(values) {
    let duplicateBookingRes = {};
    if (environment.enableBooking) {
      duplicateBookingRes = await this.doDuplicateBookingCheck(values); // calling to create an live booking
    }
    console.log("Duplicate booking check  response", duplicateBookingRes);
    if (duplicateBookingRes.hasOwnProperty("duplicate")) {
      if (duplicateBookingRes["duplicate"] == true) {
        this.duplicateBookingMsg = {
          text: duplicateBookingRes["errorMessage"],
          reference_no: duplicateBookingRes["duplicateReference"],
        };
        this.active = false;
        this.disableBtn = false;
        this.openDuplicateBookingModal();
        return false;
      } else {
        this.doBooking(values);
      }
    }
  }

  doOnlinePayment() {
    this.active = true;
    let paymentMethod = "";
    if (this.selectedPaymentMethod == "credit-card") {
      paymentMethod = "OPTCRDC";
    } else if (this.selectedPaymentMethod == "netbanking") {
      paymentMethod = "netbanking";
    } else if (this.selectedPaymentMethod == "debit") {
      paymentMethod = "debit";
    }
    let redirectUrl = `http://localhost:4200/#/pages/traveller`;
    if (environment.name != "default") {
      redirectUrl = `${environment.baseUrl}/#/pages/traveller`;
    }
    // let amount_charged = Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100;
    let limitType = "cash";
    if (!this.isDiAccount && !this.isLCC) {
      limitType = "credit";
    }
    let amount = 1;
    if (environment.name == "prod") {
      amount = this.onlinePaymentAmount;
    }
    if (this.affiliateDetails != null && this.affiliateDetails != undefined) {
      let obj = {
        affiliateCode: this.affiliateDetails.xlAccountCode,
        affiliateId: this.affiliateDetails.affiliateId,
        redirectUrl: redirectUrl,
        amount: amount,
        order_id: "PT500069",
        mobile: this.affiliateDetails.mobile,
        email: this.affiliateDetails.primaryEmail,
        payment_mode: paymentMethod,
        limit_type: limitType,
        original_amount: this.originalNetPayable,
      };

      if (this.selectedPaymentMethod == "credit-card") {
        this.travellerService.doHDFCPayment(obj);
      } else {
        this.travellerService.doFTCASHPayment(obj);
      }
    }
  }

  showError(type, message, detail) {
    if(!this.messageList){
      this.messageList = {
        status : true,
        messageList : []
      }
    }
    this.messageList["status"] = true;
    let obj = {
      warningType: type,
      message: message,
      detail: detail,
    };
    this.messageList["messageList"].push(obj);
    // this.ngRedux.dispatch(SetGlobalMessage(this.messageList))
    this.notificationService.showMessage(this.messageList);
  }

  selectAddonTab(tabName){
    this.activeAddonTab = tabName;
  }


  toggleAddonSideBar(){
    console.log("clicked on addon")
    this.toggleAddon = !this.toggleAddon
    // this.commonService.toggleAddonSideBar(this.toggleAddon)
    this.ngRedux.dispatch(ToggleAddonSummary(this.toggleAddon));
  }

  initializeSelectedSsr(requestPayload) {
    let payLoad = {
      travellers : requestPayload.travellers,
      segments : requestPayload.selectFlight['segments']
    }
		this.ngRedux.dispatch(InitializeSelectedSSR(payLoad));
	}

  getInsuranceData(searchRequest){
		let noOfAdult = searchRequest.noOfAdults;
		let	noOfChild = searchRequest.noOfChild;
		let noOfInfant = searchRequest.noOfInfants;
		let insuredPersons = Number(noOfAdult) + Number(noOfChild) + Number(noOfInfant);
		let flight_direction = searchRequest.roundTrip ? 'round_trip' : 'one_way'
		let originAirport = searchRequest.originDetails.code
		let destinationAirport = searchRequest.destinationDetails.code
		this.commonService.getInsurancePackage(`insurance/digit/v1.0/package/?no_of_insured_person=${insuredPersons}&flight_direction=${flight_direction}&origin=${originAirport}&destination=${destinationAirport}`).subscribe((res : any)=>{
			this.insuranceAmountPerPerson = 0
			this.totalInsuranceAmount = 0
			if(res && res.covers){
			this.insurancePackageData = res
			}
		})
	}

  toggleInsuranceCoverListView(){
    this.isInsuranceCoverExpanded = !this.isInsuranceCoverExpanded
    if(this.isInsuranceCoverExpanded){
      this.insuranceCoverViewCount = this.insurancePackageData.covers.length
    }else{
      this.insuranceCoverViewCount = 2
    }
  }

  toggleInsuranceSelection(isInsuranceSelected){
    this.isInsuranceSelected = isInsuranceSelected
    if(isInsuranceSelected){
      this.insuranceAmountPerPerson = this.insurancePackageData.amount_per_person
      this.totalInsuranceAmount = this.insurancePackageData.amount_per_person*this.totalPassengerCount
    }else{
      this.insuranceAmountPerPerson = 0
      this.totalInsuranceAmount = 0
    }
    this.calculateNetPayable(false)
  }


  selectInfantAssociate(parent,currentIndex){
		let arr = []
		this.selectedInfantAssociate.forEach(value=> {
		  arr.push(value)
		})
		if(arr.indexOf(currentIndex) > -1){
		  this.selectedInfantAssociate.delete(arr.indexOf(currentIndex))
		}
		this.selectedInfantAssociate.set(parent,currentIndex)
	  }

  

}
